<div class="card">
    <div class="card-header" style="text-align: center;">
        <h5>¿Desea eliminar {{nombre}}?</h5>
    </div>
    <div class="card-divider"></div>
    <div class="card-body">
        <div class="fix-buttons">
            <div class="form-group mt-5 mb-0">
                <button (click)="cancelar()" class="btn btn-primary">Cancelar</button>
            </div>
            <div class="form-group mt-5 mb-0">
                <button (click)="eliminar()" class="btn btn-primary">Eliminar</button>
            </div>
        </div>
    </div>
</div>
