<div class="nav-panel">
    <div class="nav-panel__container container">
        <div class="nav-panel__row">
            <div *ngIf="logo" class="nav-panel__logo">
                <img src="/assets/images/semilla/logo_sin_semilla.png" height="55px" width="110px" alt="">
            </div>

            <div *ngIf="departments" class="nav-panel__departments">
                <app-header-departments></app-header-departments>
            </div>

            <!-- .nav-links -->
            <app-header-links class="nav-panel__nav-links nav-links"></app-header-links>
            <!-- .nav-links / end -->

            <div class="nav-panel__indicators">
                <div *ngIf="search" class="indicator indicator--trigger--click" appDropdown="indicator--opened" #search="appDropdown">
                    <button type="button" class="indicator__button" (click)="$event.preventDefault(); search.toggle(); search.isOpen ? searchField.focus() : '';">
                        <span class="indicator__area">
                            <app-icon class="indicator__icon" name="search-20" size="20"></app-icon>
                            <app-icon class="indicator__icon indicator__icon--open" name="cross-20" size="20"></app-icon>
                        </span>
                    </button>

                    <div class="indicator__dropdown">
                        <div class="drop-search">
                            <form action="" class="drop-search__form">
                                <input class="drop-search__input" name="search" (keydown.escape)="search.close()" placeholder="Busqueda..." aria-label="Site search" type="text" autocomplete="off" #searchField>
                                <button class="drop-search__button drop-search__button--submit" type="submit">
                                    <app-icon name="search-20" size="20"></app-icon>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="indicator indicator--trigger--click" appDropdown="indicator--opened" #cartDropdown="appDropdown">
                    <a [href]="'./shop/cart'" class="indicator__button" (click)="$event.preventDefault(); cartDropdown.toggle()">
                        <span class="indicator__area">
                            <app-icon name="cart-20" size="20"></app-icon>
                            <span class="indicator__value">{{ cart.quantity$|async }}</span>
                        </span>
                    </a>

                    <div class="indicator__dropdown">
                        <app-header-dropcart (closeMenu)="cartDropdown.close()"></app-header-dropcart>
                    </div>
                </div>

                <div class="indicator indicator--trigger--click" appDropdown="indicator--opened" #accountDropdown="appDropdown">
                    <a [href]="'./account'" class="indicator__button" (click)="$event.preventDefault(); accountDropdown.toggle()">
                        <span class="indicator__area">
                            <app-icon name="person-20" size="20"></app-icon>
                        </span>
                    </a>

                    <div class="indicator__dropdown">
                        <app-account-menu (closeMenu)="accountDropdown.close()"></app-account-menu>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
