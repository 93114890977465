import { NavigationLink } from '../app/shared/interfaces/navigation-link';
import { FakeSlidesDirective } from 'src/app/shared/directives/fake-slides.directive';

export const navigation: NavigationLink[] = [
    {label: 'Inicio', url: '/'},
    //{label: 'Pedidos', url: '/site/pedidos'},
    {label: 'Cremascotas', url: '/site/cremascotas'},
    /*{label: 'Megamenu', url: '/shop/catalog', menu: {
        type: 'megamenu',
        size: 'nl',
        columns: [
            {size: 6, items: [
                {label: 'Power Tools', url: '/shop/catalog', items: [
                    {label: 'Engravers', url: '/shop/catalog'},
                    {label: 'Wrenches', url: '/shop/catalog'},
                    {label: 'Wall Chaser', url: '/shop/catalog'},
                    {label: 'Pneumatic Tools', url: '/shop/catalog'}
                ]},
                {label: 'Machine Tools', url: '/shop/catalog', items: [
                    {label: 'Thread Cutting', url: '/shop/catalog'},
                    {label: 'Chip Blowers', url: '/shop/catalog'},
                    {label: 'Sharpening Machines', url: '/shop/catalog'},
                    {label: 'Pipe Cutters', url: '/shop/catalog'},
                    {label: 'Slotting machines', url: '/shop/catalog'},
                    {label: 'Lathes', url: '/shop/catalog'}
                ]}
            ]},
            {size: 6, items: [
                {label: 'Hand Tools', url: '/shop/catalog', items: [
                    {label: 'Screwdrivers', url: '/shop/catalog'},
                    {label: 'Handsaws', url: '/shop/catalog'},
                    {label: 'Knives', url: '/shop/catalog'},
                    {label: 'Axes', url: '/shop/catalog'},
                    {label: 'Multitools', url: '/shop/catalog'},
                    {label: 'Paint Tools', url: '/shop/catalog'}
                ]},
                {label: 'Garden Equipment', url: '/shop/catalog', items: [
                    {label: 'Motor Pumps', url: '/shop/catalog'},
                    {label: 'Chainsaws', url: '/shop/catalog'},
                    {label: 'Electric Saws', url: '/shop/catalog'},
                    {label: 'Brush Cutters', url: '/shop/catalog'}
                ]}
            ]}
        ]
    }},*/
    {label: 'Productos', url: '/shop/catalog'},
    {label: 'Videos y Manuales', url: '#',  menu: {
        type: 'menu',
        items: [
            {label: 'Videos',         url: '/site/videos'},
            {label: 'Manuales',            url: '/account/manuales'}
        ]
    }},
    {label: 'Semilla', url: '#', menu: {
        type: 'menu',
        items: [
            {label: 'Quienes somos',             url: '/site/about-us'},
            {label: 'Membresia',           url: '/site/membresia'},
            {label: 'Políticas',       url: '/site/politicas'}
        ]
    }},
    {label: 'Administrador', url: '#', menu: {
        type: 'menu',
        items: [
            {label: 'Ordenes pendientes', url: '/account/ordenespen'},
            {label: 'Mantenimiento de objetos', url: '/account/manobjetos'},
            {label: 'Mantenimiento de perfiles', url: '/account/manperfiles'},
            {label: 'Mantenimiento de tipo de objetos', url: '/account/mantiposobj'},
            {label: 'Mantenimiento de usuarios', url: '/account/manusuarios'},
            {label: 'Asociar perfiles a usuarios', url: '/account/manperfusuarios'},
            {label: 'Asociar objetos a perfiles', url: '/account/manobjperf'}
        ]
    }},
    {label: 'CMS', url: '#', menu: {
        type: 'menu',
        items: [
            {label: 'Textos',           url: '/account/textos'},
            {label: 'Archivos',       url: '/account/archivos'},
            {label: 'Videos',       url: '/account/videosman'},
            {label: 'Imagenes',       url: '/account/imagenes'},
            {label: 'Pedidos',       url: '/site/pedidos'}
        ]
    }}
];

export const navigationComplete: NavigationLink[] = [
    {label: 'Inicio', url: '/'},
    //{label: 'Pedidos', url: '/site/pedidos'},
    {label: 'Cremascotas', url: '/site/cremascotas'},
    /*{label: 'Megamenu', url: '/shop/catalog', menu: {
        type: 'megamenu',
        size: 'nl',
        columns: [
            {size: 6, items: [
                {label: 'Power Tools', url: '/shop/catalog', items: [
                    {label: 'Engravers', url: '/shop/catalog'},
                    {label: 'Wrenches', url: '/shop/catalog'},
                    {label: 'Wall Chaser', url: '/shop/catalog'},
                    {label: 'Pneumatic Tools', url: '/shop/catalog'}
                ]},
                {label: 'Machine Tools', url: '/shop/catalog', items: [
                    {label: 'Thread Cutting', url: '/shop/catalog'},
                    {label: 'Chip Blowers', url: '/shop/catalog'},
                    {label: 'Sharpening Machines', url: '/shop/catalog'},
                    {label: 'Pipe Cutters', url: '/shop/catalog'},
                    {label: 'Slotting machines', url: '/shop/catalog'},
                    {label: 'Lathes', url: '/shop/catalog'}
                ]}
            ]},
            {size: 6, items: [
                {label: 'Hand Tools', url: '/shop/catalog', items: [
                    {label: 'Screwdrivers', url: '/shop/catalog'},
                    {label: 'Handsaws', url: '/shop/catalog'},
                    {label: 'Knives', url: '/shop/catalog'},
                    {label: 'Axes', url: '/shop/catalog'},
                    {label: 'Multitools', url: '/shop/catalog'},
                    {label: 'Paint Tools', url: '/shop/catalog'}
                ]},
                {label: 'Garden Equipment', url: '/shop/catalog', items: [
                    {label: 'Motor Pumps', url: '/shop/catalog'},
                    {label: 'Chainsaws', url: '/shop/catalog'},
                    {label: 'Electric Saws', url: '/shop/catalog'},
                    {label: 'Brush Cutters', url: '/shop/catalog'}
                ]}
            ]}
        ]
    }},*/
    {label: 'Productos', url: '/shop/catalog'},
    {label: 'Videos y Manuales', url: '#', menu: {
        type: 'menu',
        items: [
            {label: 'Videos',         url: '/site/videos'},
            {label: 'Manuales',            url: '/account/manuales'}
        ]
    }},
    {label: 'Semilla', url: '#', menu: {
        type: 'menu',
        items: [
            {label: 'Quienes somos',             url: '/site/about-us'},
            {label: 'Membresia',           url: '/site/membresia'},
            {label: 'Políticas',       url: '/site/politicas'}
        ]
    }},
    {label: 'Administrador', url: '#', menu: {
        type: 'menu',
        items: [
            {label: 'Ordenes pendientes', url: '/account/ordenespen'},
            {label: 'Mantenimiento de objetos', url: '/account/manobjetos'},
            {label: 'Mantenimiento de perfiles', url: '/account/manperfiles'},
            {label: 'Mantenimiento de tipo de objetos', url: '/account/mantiposobj'},
            {label: 'Mantenimiento de usuarios', url: '/account/manusuarios'},
            {label: 'Asociar perfiles a usuarios', url: '/account/manperfusuarios'},
            {label: 'Asociar objetos a perfiles', url: '/account/manobjperf'}
        ]
    }},
    {label: 'CMS', url: '#', menu: {
        type: 'menu',
        items: [
            {label: 'Textos',           url: '/account/textos'},
            {label: 'Archivos',       url: '/account/archivos'},
            {label: 'Videos',       url: '/account/videosman'},
            {label: 'Imagenes',       url: '/account/imagenes'},
            {label: 'Pedidos',       url: '/site/pedidos'}
        ]
    }}
];
