<!-- .block-banner -->
<div class="block block-banner">
    <div class="container">
        <a href="" class="block-banner__body">
            <div class="block-banner__image block-banner__image--desktop" style="background-image: url('assets/images/banners/banner-1.jpg')"></div>
            <div class="block-banner__image block-banner__image--mobile" style="background-image: url('assets/images/banners/banner-1-mobile.jpg')"></div>
            <div class="block-banner__title">Hundreds <br class="block-banner__mobile-br"> Hand Tools</div>
            <div class="block-banner__text">Hammers, Chisels, Universal Pliers, Nippers, Jigsaws, Saws</div>
            <div class="block-banner__button">
                <span  class="btn btn-sm btn-primary">Compra Ahora</span>
            </div>
        </a>
    </div>
</div>
<!-- .block-banner / end -->
