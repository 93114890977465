<mat-dialog-content>
    <mat-list>
        <div mat-subheader>Videos</div>
        <mat-list-item (click)="seleccionar(folder)" class="item-video" *ngFor="let folder of folders">
          <mat-icon mat-list-icon>videocam</mat-icon>
          <div mat-line>{{folder.NOMBRE}}</div>
          <div mat-line> {{folder.INFORMACION}} </div>
        </mat-list-item>
    </mat-list>
</mat-dialog-content>
