import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { EliminarComponent } from '../eliminar/eliminar.component';

@Component({
  selector: 'app-modal-prueba',
  templateUrl: './modal-prueba.component.html',
  styleUrls: ['./modal-prueba.component.scss']
})
export class ModalPruebaComponent implements OnInit {

  constructor(public ngxSmartModalService: NgxSmartModalService) {

  }

  ngOnInit(): void {
  }

  agregar(){
    //this.ngxSmartModalService.create('eliminar', EliminarComponent).setData({add: true, data: ""}).open()
  }

  modificar(){
    //this.ngxSmartModalService.create('eliminar', EliminarComponent).setData({add: false, data: ""}).open()
  }

  eliminar(){
    //this.ngxSmartModalService.create('eliminar', EliminarComponent).setData({nombre: 'hola', idElement:1, idObject: 1}).open();
  }

}
