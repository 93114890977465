import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SemillaDireccion } from 'src/app/shared/interfaces/semilla/perfil/semilla-direccion';
import { SemillaService } from 'src/app/shared/api/semilla.service';

@Component({
  selector: 'app-direcciones-modificar',
  templateUrl: './direcciones-modificar.component.html',
  styleUrls: ['./direcciones-modificar.component.scss']
})
export class DireccionesModificarComponent implements OnInit {
  direccion: SemillaDireccion
  interior: boolean
  interiorMensaje: string

  constructor(public ngxSmartModalService: NgxSmartModalService, private semilla: SemillaService) { 
    this.direccion = ngxSmartModalService.getModalData('mod-dir').data
    this.interior = false
    this.interiorMensaje = ""
  }

  ngOnInit(): void {
  }

  cambioDir(event: any){
    if(this.direccion.REGION == "SERVICIO 0"){
      this.interior = true
      this.interiorMensaje = "Para el interior del país, el envío se realiza por medio Guatex. Se utilizará la modalidad por pagar para el mismo, por lo que, serán ellos quienes cobren el envío."
    }else{
      this.interior = false
    }
  }

  modificar(){
    this.semilla.ModificarDireccion(localStorage.getItem('token'),this.direccion)
    .subscribe(res => {
      if(res.split("|")[0] == "0"){
        this.cancelar()
      }else{
        
      }   
    }, err => {

    })
  }

  cancelar(){
    this.ngxSmartModalService.getModal('mod-dir').close();
  }

}
