import { Component, OnInit } from '@angular/core';
import { TipoObjeto } from 'src/app/shared/interfaces/semilla/tipo-objeto';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SemillaService } from 'src/app/shared/api/semilla.service';

@Component({
  selector: 'app-tipos-crear',
  templateUrl: './tipos-crear.component.html',
  styleUrls: ['./tipos-crear.component.scss']
})
export class TiposCrearComponent implements OnInit {
  tipo:TipoObjeto

  constructor(public ngxSmartModalService: NgxSmartModalService, private semilla: SemillaService) {
    this.tipo = {
      NOMBRE: "",
      DESCRIPCION: "",
      ACTIVO: true,
      FECHA_INGRESO: "",
      FECHA_MODIFICACION: "",
      ID_TIPO_OBJETO: 0,
      USUARIO_INGRESO: "",
      USUARIO_MODIFICACION: ""
    }
  }

  ngOnInit(): void {
  }

  cancelar(){
    this.ngxSmartModalService.getModal('add-tipo').close();
  }

  agregar(){
    this.semilla.CrearTipoObjeto(localStorage.getItem('token'),this.tipo)
    .subscribe(res => {
      if(res.split("|")[0] == "0"){
        this.cancelar()
      }else{
        
      }   
    }, err => {

    })
  }

}
