import { Injectable } from '@angular/core';
import { NgxSoapService, Client, ISoapMethodResponse } from 'ngx-soap';
import { map, switchMap} from 'rxjs/operators';
import { Observable, from } from 'rxjs';
import { PerfilObjeto } from 'src/app/models/PerfilObjeto';
import { Perfil } from 'src/app/models/Perfil';
import { ClienteModel } from 'src/app/models/ClienteModel';
import { stringify } from 'querystring';
import { Parametro } from 'src/app/models/Parametro';
import { JsonPipe } from '@angular/common';
import { Cliente } from '../interfaces/semilla/cliente';
import { SemillaDireccion } from '../interfaces/semilla/perfil/semilla-direccion';
import { OrdenCompra } from '../interfaces/semilla/orden/orden-compra';
import { SemillaCliente } from '../interfaces/semilla/perfil/semilla-cliente';
import { Router } from '@angular/router';
import { Objeto } from '../interfaces/semilla/objeto';
import { TipoObjeto } from '../interfaces/semilla/tipo-objeto';
import { PerfilMantenimiento } from '../interfaces/semilla/perfil';
import { ClienteMantenimiento } from '../interfaces/semilla/cliente-man';
import { Archivo } from '../interfaces/semilla/archivo';
import { AsociaPerfil } from '../interfaces/semilla/asocia-perfil';
import { AsociaObjeto } from '../interfaces/semilla/asocia-objeto';
import * as jwt_decode from 'jwt-decode';
import { PedidoCorreo } from '../interfaces/semilla/orden/pedido';

@Injectable({
  providedIn: 'root'
})
export class SemillaService {

  constructor(private soap: NgxSoapService, private router: Router) { 

  }


  CambiarClave(usuario: string, clave: string, claveNueva: string): Observable<boolean>{
    return this.getBackendResponse("CambiaClave",{Usuario: usuario, Clave: clave, ClaveNueva: claveNueva}).pipe(map(res => {
      let response:string = res.result.CambiaClaveResponse

      return response.split("|")[0] == "0"; 
    }));
  }

  DatosBienvenida(idUsuario: number): Observable<string>{
    return this.getBackendResponse("DatosBienvenida", {idUsuario: idUsuario}).pipe(map(res => {
      return res.result.DatosBienvenidaResult;
    }));
  }

  DevuelveHijosObjeto(idPerfil:number, idObjeto:number): Observable<PerfilObjeto[]>{
    return this.getBackendResponse("DevuelveHijosObjeto", {idPerfil:idPerfil, idObjeto:idObjeto}).pipe(map(res =>{
      return <PerfilObjeto[]>JSON.parse(JSON.parse(res.result.DevuelveHijosObjetoResult));
    }));
  }

  DevuelveParametros(idUsuario: number): Observable<Parametro[]>{
    return this.getBackendResponse("DevuelveParametros",{idUsuario: idUsuario}).pipe(map(res => {
      return <Parametro[]>JSON.parse(res.result.DevuelveParametrosResult);
    }))
  }

  DevuelvePerfiles(idUsuario: Number): Observable<Perfil[]>{
    return this.getBackendResponse("DevuelvePerfiles", {idUsuario: idUsuario}).pipe(map(res =>{
      return <Perfil[]>JSON.parse(JSON.parse(res.result.DevuelvePerfilesResult));
    }));
  }

  DevuelvePerfilesObjeto(idUsuario: Number): Observable<PerfilObjeto[]>{
    return this.getBackendResponse("DevuelvePerfilesObjeto", {idUsuario: idUsuario}).pipe(map(res =>{
      return <PerfilObjeto[]>JSON.parse(JSON.parse(res.result.DevuelvePerfilesObjetoResult));
    }));
  }

  PruebaConexion(): Observable<boolean>{
    return this.getBackendResponse("PruebaConexion", {}).pipe(map(res => {
      return res.result.PruebaConexionResult == 'Conexion Exitosa';
    }));
  }

  RegistraObjetosPerfil(objetoPerfil: PerfilObjeto): Observable<boolean>{
    return this.getBackendResponse("RegistraObjetosPerfil",{Datos: JSON.stringify(objetoPerfil)}).pipe(map(res => {
      let response:string = res.result.RegistraObjetosPerfilResponse
      return response.split("|")[0] == "0"; 
    }));
  }

  RegistraPerfilesUsuario(perfilUsuario: Perfil): Observable<boolean>{
    return this.getBackendResponse("RegistrarPerfilesUsuarios",{Datos: JSON.stringify(perfilUsuario)}).pipe(map(res =>{
      let response:string = res.result.RegistrarPerfilesUsuariosResponse
      return response.split("|")[0] == "0"; 
    }));
  }

  RegistroCliente(cliente: Cliente): Observable<string>{
    return this.getBackendResponse("RegistroCliente", {Datos: JSON.stringify(cliente)}).pipe(map(res => {
      return res.result.RegistroClienteResult; 
    }));
  }

  ValidaUser(user: string, pass: string): Observable<Number>{
    return this.getBackendResponse("LoginNuevo", {user:user, pass:pass}).pipe(map(res => {     
      return Number.parseInt(res.result.LoginNuevoResult);
    }));
  }

  Login(user: string, pass: string): Observable<any>{
    return this.getBackendResponse("LoginNuevo",  {username:user, password:pass}).pipe(map(res => {  
      
      if(res.result.LoginNuevoResult.split('|')[0] == '1'){
        return {
          status: res.result.LoginNuevoResult.split('|')[0],
          token: null
        }
      }else{
        return {
          status: res.result.LoginNuevoResult.split('|')[0],
          nombre: res.result.LoginNuevoResult.split('|')[3],
          correo: res.result.LoginNuevoResult.split('|')[4],
          expiracion:res.result.LoginNuevoResult.split('|')[5],
          token: res.result.LoginNuevoResult.split('|')[6],
          id: res.result.LoginNuevoResult.split('|')[1]
        }
      }
    }));
  }

  //FLUJOS
  ObtenerFlujos(token:string): Observable<string>{
    return this.getBackendResponse('LeerFlujos',{token: token, idObjeto: 5}).pipe(map( res => {
      return res.result.LeerFlujosResult
    }));
  }

  SiguienteEstado(token:string, idFlujo: number): Observable<string>{
    return this.getBackendResponse('SiguienteEstado',{token: token, idObjeto: 5, idFlujoActual: idFlujo}).pipe(map( res => {
      return res.result.SiguienteEstadoResult
    }));
  }

  EstadoAnterior(token:string, idFlujo: number): Observable<string>{
    return this.getBackendResponse('AnteriorEstado',{token: token, idObjeto: 5, idFlujoActual: idFlujo}).pipe(map( res => {
      return res.result.AnteriorEstadoResult
    }));
  }

  EstadoCancelar(token:string, idFlujo: number): Observable<string>{
    return this.getBackendResponse('CancelarFlujo',{token: token, idObjeto: 5, idFlujoActual: idFlujo}).pipe(map( res => {
      return res.result.CancelarFlujoResult
    }));
  }


  //PERMISOS
  ValidarObjeto(token: string, id: number): Observable<string>{
    return this.getBackendResponse("ValidarObjeto", {token: token, idObjeto: id}).pipe(map(res => {
      return res.result.ValidarObjetoResult; 
    }));
  }

  //PERFIL
  CambiarContraseña(token: string, oldPass: string, newPass: string):Observable<string>{
    return this.getBackendResponse('CambiarPassword',{token: token, oldPass: oldPass, newPass: newPass}).pipe(map( res => {
      return res.result.CambiarPasswordResult
    }));
  }

  ObtenerPerfil(token: string):Observable<string>{
    return this.getBackendResponse('LeerDatosCliente',{token: token}).pipe(map( res => {
      return res.result.LeerDatosClienteResult
    }));
  }

  ModificarPerfil(token: string, datosCliente:SemillaCliente):Observable<string>{
    let datos:any = datosCliente
    delete datos["ID_CLIENTE"]
    return this.getBackendResponse('ModificarDatosCliente',{token: token, datosCliente: JSON.stringify(datosCliente)}).pipe(map( res => {
      return res.result.ModificarDatosClienteResult
    }));
  }

  //ORDENES
  CrearOrden(datos: OrdenCompra):Observable<string>{
    console.log(JSON.stringify(datos))
    return this.getBackendResponse('CrearOC',{Datos: JSON.stringify(datos)}).pipe(map( res => {
      return res.result.CrearOCResult
    }));
  }

  ObtenerOrdenes(token:string): Observable<string>{
    return this.getBackendResponse('LeerOrdenesCompra',{token: token}).pipe(map( res => {
      return res.result.LeerOrdenesCompraResult
    }));
  }

  ObtenerDetalleOrden(token:string, idOrden: number): Observable<string>{
    return this.getBackendResponse('LeerDetalleOrdenCompra',{token: token, idObjeto: 1, idOrdenCompra: idOrden}).pipe(map( res => {
      return res.result.LeerDetalleOrdenCompraResult
    }));
  }

  //DIRECCIONES

  CrearDireccion(token: string, direccion:SemillaDireccion):Observable<string>{
    return this.getBackendResponse('CrearDireccion',{token: token, Datos: JSON.stringify(direccion)}).pipe(map( res => {
      return res.result.CrearDireccionResult
    }));
  }

  EliminarDireccion(token: string, id: number):Observable<string>{
    return this.getBackendResponse('EliminarDireccion',{token: token, idDireccion: id}).pipe(map( res => {
      return res.result.EliminarDireccionResult
    }));
  }

  ModificarDireccion(token: string, direccion:SemillaDireccion):Observable<string>{
    return this.getBackendResponse('ModificarDireccion',{token: token, Datos: JSON.stringify(direccion)}).pipe(map( res => {
      return res.result.ModificarDireccionResult
    }));
  }

  ObtenerDirecciones(token: string):Observable<string>{
    return this.getBackendResponse('LeerDirecciones',{token: token}).pipe(map( res => {
      return res.result.LeerDireccionesResult
    }));
  }

  //Objeto
  CrearObjeto(token: string, objeto:Objeto):Observable<string>{
    return this.getBackendResponse('CrearObjeto',{token: token, datos: JSON.stringify(objeto)}).pipe(map( res => {
      return res.result.CrearObjetoResult
    }));
  }

  EliminarObjeto(token: string, id: number):Observable<string>{
    return this.getBackendResponse('EliminarObjeto',{token: token, id: id}).pipe(map( res => {
      return res.result.EliminarObjetoResult
    }));
  }

  ModificarObjeto(token: string, objeto:Objeto):Observable<string>{
    return this.getBackendResponse('ActualizarObjeto',{token: token, datos: JSON.stringify(objeto)}).pipe(map( res => {
      return res.result.ActualizarObjetoResult
    }));
  }

  ObtenerObjetos(token: string):Observable<string>{
    return this.getBackendResponse('LeerObjetos',{token: token}).pipe(map( res => {
      return res.result.LeerObjetosResult
    }));
  }

  //Perfil
  CrearPerfil(token: string, perfil:PerfilMantenimiento):Observable<string>{
    return this.getBackendResponse('CrearPerfil',{token: token, datos: JSON.stringify(perfil)}).pipe(map( res => {
      return res.result.CrearPerfilResult
    }));
  }

  EliminarPerfil(token: string, id: number):Observable<string>{
    return this.getBackendResponse('EliminarPerfil',{token: token, id: id}).pipe(map( res => {
      return res.result.EliminarPerfilResult
    }));
  }

  ModificarPerfilMan(token: string, perfil: PerfilMantenimiento):Observable<string>{
    return this.getBackendResponse('ActualizarPerfil',{token: token, datos: JSON.stringify(perfil)}).pipe(map( res => {
      return res.result.ActualizarPerfilResult
    }));
  }

  ObtenerPerfiles(token: string):Observable<string>{
    return this.getBackendResponse('LeerPerfiles',{token: token}).pipe(map( res => {
      return res.result.LeerPerfilesResult
    }));
  }

  //TipoObjeto

  CrearTipoObjeto(token: string, perfil:TipoObjeto):Observable<string>{
    return this.getBackendResponse('CrearTipoObjeto',{token: token, datos: JSON.stringify(perfil)}).pipe(map( res => {
      return res.result.CrearTipoObjetoResult
    }));
  }

  EliminarTipoObjeto(token: string, id: number):Observable<string>{
    return this.getBackendResponse('EliminarTipoObjeto',{token: token, id: id}).pipe(map( res => {
      return res.result.EliminarTipoObjetoResult
    }));
  }

  ModificarTipoObjeto(token: string, objeto:TipoObjeto):Observable<string>{
    return this.getBackendResponse('ActualizarTipoObjeto',{token: token, datos: JSON.stringify(objeto)}).pipe(map( res => {
      return res.result.ActualizarTipoObjetoResult
    }));
  }

  ObtenerTiposObjetos(token: string):Observable<string>{
    return this.getBackendResponse('LeerTiposObjetos',{token: token}).pipe(map( res => {
      return res.result.LeerTiposObjetosResult
    }));
  }

  //Usuarios
  EliminarUsuario(token: string, id: number):Observable<string>{
    return this.getBackendResponse('EliminarDatosClientes',{token: token, id: id}).pipe(map( res => {
      return res.result.EliminarDatosClientesResult
    }));
  }

  ModificarUsuario(token: string, cliente:ClienteMantenimiento):Observable<string>{
    return this.getBackendResponse('ModificarDatosCliente',{token: token, datosCliente: JSON.stringify(cliente)}).pipe(map( res => {
      return res.result.ModificarDatosClienteResult
    }));
  }

  ObtenerUsuarios(token: string):Observable<string>{
    return this.getBackendResponse('LeerDatosClientes',{token: token}).pipe(map( res => {
      return res.result.LeerDatosClientesResult
    }));
  }

  //PerfilesUsuarios
  ObtenerPerfilesUsuario(token: string, id: number):Observable<string>{
    return this.getBackendResponse('LeerPerfilUsuario',{token: token, id: id}).pipe(map( res => {
      return res.result.LeerPerfilUsuarioResult
    }));
  }

  ModificarPerfilesUsuario(token: string, data: AsociaPerfil):Observable<string>{
    return this.getBackendResponse('ActualizarPerfilUsuario',{token: token, datos: JSON.stringify(data)}).pipe(map( res => {
      return res.result.ActualizarPerfilUsuarioResult
    }));
  }

  CrearPerfilesUsuario(token: string, data: AsociaPerfil):Observable<string>{
    return this.getBackendResponse('CrearPerfilUsuario',{token: token, datos: JSON.stringify(data)}).pipe(map( res => {
      return res.result.CrearPerfilUsuarioResult
    }));
  }

  //ObjetosPerfiles
  ObtenerObjetosPerfiles(token: string, id: number):Observable<string>{
    return this.getBackendResponse('LeerPerfilObjeto',{token: token, id: id}).pipe(map( res => {
      return res.result.LeerPerfilObjetoResult
    }));
  }

  ModificarObjetosPerfiles(token: string, data: AsociaObjeto):Observable<string>{
    return this.getBackendResponse('ActualizarPerfilObjeto',{token: token, datos: JSON.stringify(data)}).pipe(map( res => {
      return res.result.ActualizarPerfilObjetoResult
    }));
  }

  CrearObjetosPerfiles(token: string, data: AsociaObjeto):Observable<string>{
    return this.getBackendResponse('CrearPerfilObjeto',{token: token, datos: JSON.stringify(data)}).pipe(map( res => {
      return res.result.CrearPerfilObjetoResult
    }));
  }

  //CMS

  //Archivos

  CrearArchivo(token: string, perfil:Archivo):Observable<string>{
    return this.getBackendResponse('CrearArchivos',{token: token, datos: JSON.stringify(perfil)}).pipe(map( res => {
      return res.result.CrearArchivosResult
    }));
  }

  EliminarArchivo(token: string, id: number):Observable<string>{
    return this.getBackendResponse('EliminarArchivos',{token: token, id: id}).pipe(map( res => {
      return res.result.EliminarArchivosResult
    }));
  }

  ModificarArchivo(token: string, objeto:Archivo):Observable<string>{
    return this.getBackendResponse('ActualizarArchivos',{token: token, datos: JSON.stringify(objeto)}).pipe(map( res => {
      return res.result.ActualizarArchivosResult
    }));
  }

  ObtenerArchivos(token: string):Observable<string>{
    return this.getBackendResponseWithoutToken('LeerArchivos',{}).pipe(map( res => {
      return res.result.LeerArchivosResult
    }));
  }

  //Videos

  CrearVideo(token: string, perfil:Archivo):Observable<string>{
    return this.getBackendResponse('CrearVideos',{token: token, datos: JSON.stringify(perfil)}).pipe(map( res => {
      return res.result.CrearVideosResult
    }));
  }

  EliminarVideo(token: string, id: number):Observable<string>{
    return this.getBackendResponse('EliminarVideos',{token: token, id: id}).pipe(map( res => {
      return res.result.EliminarVideosResult
    }));
  }

  ModificarVideo(token: string, objeto:Archivo):Observable<string>{
    return this.getBackendResponse('ActualizarVideos',{token: token, datos: JSON.stringify(objeto)}).pipe(map( res => {
      return res.result.ActualizarVideosResult
    }));
  }

  ObtenerVideos(token: string):Observable<string>{
    return this.getBackendResponseWithoutToken('LeerVideos',{}).pipe(map( res => {
      return res.result.LeerVideosResult
    }));
  }

  //Imagenes

  CrearImagen(token: string, perfil:Archivo):Observable<string>{
    return this.getBackendResponse('CrearImagenes',{token: token, datos: JSON.stringify(perfil)}).pipe(map( res => {
      return res.result.CrearImagenesResult
    }));
  }

  EliminarImagen(token: string, id: number):Observable<string>{
    return this.getBackendResponse('EliminarImagenes',{token: token, id: id}).pipe(map( res => {
      return res.result.EliminarImagenesResult
    }));
  }

  ModificarImagen(token: string, objeto:Archivo):Observable<string>{
    return this.getBackendResponse('ActualizarImagenes',{token: token, datos: JSON.stringify(objeto)}).pipe(map( res => {
      return res.result.ActualizarImagenesResult
    }));
  }

  ObtenerImagen(token: string):Observable<string>{
    return this.getBackendResponseWithoutToken('LeerImagenes',{}).pipe(map( res => {
      return res.result.LeerImagenesResult
    }));
  }

  //Textos

  CrearTextos(token: string, perfil:Archivo):Observable<string>{
    return this.getBackendResponse('CrearTextos',{token: token, datos: JSON.stringify(perfil)}).pipe(map( res => {
      return res.result.CrearTextosResult
    }));
  }

  EliminarTextos(token: string, id: number):Observable<string>{
    return this.getBackendResponse('EliminarTextos',{token: token, id: id}).pipe(map( res => {
      return res.result.EliminarTextosResult
    }));
  }

  ModificarTextos(token: string, objeto:Archivo):Observable<string>{
    return this.getBackendResponse('ActualizarTextos',{token: token, datos: JSON.stringify(objeto)}).pipe(map( res => {
      return res.result.ActualizarTextosResult
    }));
  }

  ObtenerTextos(token: string):Observable<string>{
    return this.getBackendResponseWithoutToken('LeerTextos',{}).pipe(map( res => {
      return res.result.LeerTextosResult
    }));
  }

  //ENVIOS
  ObtenerEnvios():Observable<string>{
    return this.getBackendResponseWithoutToken('LeerEnvios',{}).pipe(map( res => {
      return res.result.LeerEnviosResult
    }));
  }

  //EnvioPassword
  EnviarPassword(mail: string):Observable<string>{
    return this.getBackendResponseWithoutToken('EnviarPassword',{mail:mail}).pipe(map( res => {
      return res.result.EnviarPasswordResult
    }));
  }

  //EnvioPassword
  EnviarPedido(pedido: PedidoCorreo):Observable<string>{
    return this.getBackendResponseWithoutToken('EnviarCorreo',{Datos:JSON.stringify(pedido)}).pipe(map( res => {
      return res.result.EnviarCorreoResult
    }));
  }

  ValidarHumano(token: string):Observable<string>{
    return this.getBackendResponseWithoutToken('ConsumirGoogleApi',{token:token}).pipe(map( res => {
      return res.result.ConsumirGoogleApiResult
    }));
  }

  //ValidarRobot


  //Backend WSDL manager
  getBackendResponseWithoutToken(method: string, params: any): Observable<ISoapMethodResponse>{
    return this.getClient().pipe(switchMap((res) => {
      return res.call(method, params)
    }));
  }

  getBackendResponse(method: string, params: any): Observable<ISoapMethodResponse>{
    this.tokenValidator()
    
    return this.getClient().pipe(switchMap((res) => {
      return res.call(method, params)
    }));
  }

  getClient(): Observable<Client>{
    return from(this.soap.createClient('assets/Semilla.wsdl').then((res) => {return res;}));
  }

  tokenValidator(){
    if(localStorage.getItem('token') == null)
      return

    const decoded = jwt_decode(localStorage.getItem('token'));

    if (decoded.exp === undefined) return null;
  
    const date = new Date(0); 
    date.setUTCSeconds(decoded.exp);

    if(new Date() > date){
      localStorage.setItem('session', 't')
      this.router.navigate(['/home/login'])
    }
  }
}
