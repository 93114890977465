<div class="card">
    <div class="card-header">
        <h5>Modificar Asociación</h5>
    </div>
    <div class="card-divider"></div>
    <div class="card-body">
        <div class="row no-gutters">
            <div class="col-12 col-lg-7 col-xl-6 fix-modal">
                <div class="form-group">
                    <label>Nombre Objeto</label>
                    <input [(ngModel)]="objeto.NOMBRE" disabled type="text" class="form-control" placeholder="Ingresa nombre">
                </div>
                <div class="form-group">
                    <label>Nombre Perfil</label>
                    <input [(ngModel)]="perfil.NOMBRE" disabled type="text" class="form-control" placeholder="Ingresa perfil">
                </div>
                <div class="form-group" >
                    <label>Asociado</label>
                    <input [(ngModel)]="objetoPerfil.ACTIVO" type="checkbox" name="" id="">
                </div>
                <div class="form-group" >
                    <label>Crear</label>
                    <input [(ngModel)]="objetoPerfil.CREAR" type="checkbox" name="" id="">
                </div>
                <div class="form-group" >
                    <label>Consultar</label>
                    <input [(ngModel)]="objetoPerfil.CONSULTAR" type="checkbox" name="" id="">
                </div>
                <div class="form-group" >
                    <label>Modificar</label>
                    <input [(ngModel)]="objetoPerfil.MODIFICAR" type="checkbox" name="" id="">
                </div>
                <div class="form-group" >
                    <label>Eliminar</label>
                    <input [(ngModel)]="objetoPerfil.ELIMINAR" type="checkbox" name="" id="">
                </div>
                <div class="fix-buttons">
                    <div class="form-group mt-5 mb-0">
                        <button (click)="cancelar()" class="btn btn-primary">Cancelar</button>
                    </div>
                    <div class="form-group mt-5 mb-0">
                        <button (click)="modificar()" class="btn btn-primary">Modificar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>