<div class="footer-newsletter">
    <!--
    <h5 class="footer-newsletter__title">Newsletter</h5>
    <div class="footer-newsletter__text">
        Praesent pellentesque volutpat ex, vitae auctor lorem pulvinar mollis felis at lacinia.
    </div>

    <form action="" class="footer-newsletter__form">
        <label class="sr-only" for="footer-newsletter-address">Email Address</label>
        <input type="text" class="footer-newsletter__form-input form-control" id="footer-newsletter-address" placeholder="Email Address...">

        <button class="footer-newsletter__form-button btn btn-primary">Subscribe</button>
    </form>
    -->

    <div class="footer-newsletter__text footer-newsletter__text--social">
        Siguenos en nuestras redes sociales
    </div>

    <ul class="footer-newsletter__social-links">
        <li class="footer-newsletter__social-link footer-newsletter__social-link--facebook"><a [href]="theme.author.profile_fb" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
        <li class="footer-newsletter__social-link footer-newsletter__social-link--twitter"><a [href]="theme.author.profile_tw" target="_blank"><i class="fab fa-twitter"></i></a></li>
        <li class="footer-newsletter__social-link footer-newsletter__social-link--youtube"><a [href]="theme.author.profile_yt" target="_blank"><i class="fab fa-youtube"></i></a></li>
        <li class="footer-newsletter__social-link footer-newsletter__social-link--instagram"><a [href]="theme.author.profile_in" target="_blank"><i class="fab fa-instagram"></i></a></li>
    </ul>
    
</div>
