<div class="site-footer">
    <div class="container">
        <div class="site-footer__widgets">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                    <app-footer-contacts></app-footer-contacts>
                </div>
                <div class="col-6 col-md-3 col-lg-2">
                    <app-footer-links header="Información" [links]="[
                        {label: 'Quienes Somos',             url: '/site/about-us'},
                        {label: 'Cremascotas', url: '/site/cremascotas'},
                        {label: 'Videos',       url: '/site/videos'},
                        {label: 'Productos',               url: '/shop/catalog'},
                        {label: 'Manuales',           url: '/account/manuales'},
                        {label: 'Membresia',              url: '/site/membresia'},
                        {label: 'Politicas',             url: '/site/politicas'}
                    ]"></app-footer-links>
                </div>
                <div class="col-6 col-md-3 col-lg-2">
                    <app-footer-links header="Mi cuenta" [links]="[
                        {label: 'Historial de Compras', url: '/account/orders'},
                        {label: 'Info. General',  url: '/account/profile'},
                        {label: 'Direcciones',      url: '/account/addresses'}
                    ]"></app-footer-links>
                </div>
                <div class="col-12 col-md-12 col-lg-4">
                    <app-footer-newsletter class="site-footer__widget"></app-footer-newsletter>
                </div>
            </div>
        </div>

        <div class="site-footer__bottom">
            <!--
            <div class="site-footer__copyright">
                Powered by <a href="https://angular.io/" target="_blank">Angular</a> — Design by <a [href]="theme.author.profile_url" target="_blank">{{ theme.author.name }}</a>
            </div>
            <div class="site-footer__payments">
                <img src="assets/images/payments.png" alt="">
            </div>
            -->
        </div>
    </div>
</div>
