import { Component, OnInit } from '@angular/core';
import { Archivo } from 'src/app/shared/interfaces/semilla/archivo';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SemillaService } from 'src/app/shared/api/semilla.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-textos-modificar',
  templateUrl: './textos-modificar.component.html',
  styleUrls: ['./textos-modificar.component.scss']
})
export class TextosModificarComponent implements OnInit {
  multimedia: Archivo
  nombre: string

  constructor(public ngxSmartModalService: NgxSmartModalService, private semilla:SemillaService, public dialog: MatDialog) { 
    this.nombre = ngxSmartModalService.getModalData('mod-tex').nombre
    this.multimedia = ngxSmartModalService.getModalData('mod-tex').data

    if(this.multimedia.NOMBRE == "CAT1"){
      this.multimedia.INFORMACION = this.b64DecodeUnicode(this.multimedia.DATA)
    }
  }

  arrayBufferToBase64( buffer ): any {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode(parseInt(p1, 16))
    }))
  }

  b64DecodeUnicode(str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  }


  ngOnInit(): void {
  }

  modificar(){
    switch(this.nombre){
      case 'Texto':
        if(this.multimedia.NOMBRE == "CAT1"){
          this.multimedia.DATA = this.b64EncodeUnicode(this.multimedia.INFORMACION)
          this.multimedia.INFORMACION = "data"
        }

        this.semilla.ModificarTextos(localStorage.getItem('token'),this.multimedia).subscribe( res => {
          if(res.split("|")[0] == "0"){
            this.cancelar()
            
          }else{
            
          }      
        }, err => {
          
        });
      break

      case 'Video':
        this.semilla.ModificarVideo(localStorage.getItem('token'),this.multimedia).subscribe( res => {
          if(res.split("|")[0] == "0"){
            this.cancelar()
            
          }else{
            
          }      
        }, err => {
          
        });
      break
      
      case 'Imagen':
        this.semilla.ModificarImagen(localStorage.getItem('token'),this.multimedia).subscribe( res => {
          if(res.split("|")[0] == "0"){
            this.cancelar()
            
          }else{
            
          }      
        }, err => {
          
        });
      break
    }
  }

  cancelar(){
    this.ngxSmartModalService.getModal('mod-tex').close();
  }

}
