import { Component } from '@angular/core';
import { MobileMenuService } from '../../../../shared/services/mobile-menu.service';
import { WishlistService } from '../../../../shared/services/wishlist.service';
import { CartService } from '../../../../shared/services/cart.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-mobile-header',
    templateUrl: './mobile-header.component.html',
    styleUrls: ['./mobile-header.component.scss']
})
export class MobileHeaderComponent {
    sesion: boolean
    palabra: string
    constructor(
        public menu: MobileMenuService,
        public wishlist: WishlistService,
        public cart: CartService,
        private router: Router
    ) { 
        this.palabra = ""
        this.sesion = localStorage.getItem('token') != null
    }

    busqueda(){
        if(this.palabra.trim() == '')
            return

        localStorage.setItem('busqueda', this.palabra)

        if(this.router.url != '/shop/catalog'){
            this.router.navigate(['../shop/catalog'])
        }else{
            window.location.reload()
        }
        
    }
}
