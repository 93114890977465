import { Component } from '@angular/core';
import { navigation, navigationComplete } from '../../../../../data/header-navigation';
import { NavigationLink } from '../../../../shared/interfaces/navigation-link';
import { DirectionService } from '../../../../shared/services/direction.service';
import { SemillaService } from 'src/app/shared/api/semilla.service';

@Component({
    selector: 'app-header-links',
    templateUrl: './links.component.html',
    styleUrls: ['./links.component.scss']
})
export class LinksComponent {
    items: NavigationLink[] = navigation;
    hoveredItem: NavigationLink = null;

    constructor(
        private direction: DirectionService,
        private semilla: SemillaService
    ) {
        this.items = navigation
        this.validarToken()
    }

    onItemMouseEnter(item: NavigationLink, event: MouseEvent): void {
        this.hoveredItem = item;

        if (!(event.target instanceof HTMLElement)) {
            return;
        }

        const element: HTMLElement = event.target;
        const megamenu = element.querySelector('.nav-links__megamenu') as HTMLElement;

        if (!megamenu) {
            return;
        }

        const container = megamenu.offsetParent;
        const containerWidth = container.getBoundingClientRect().width;
        const megamenuWidth = megamenu.getBoundingClientRect().width;

        if (this.direction.isRTL()) {
            const itemPosition = containerWidth - (element.offsetLeft + element.offsetWidth);
            const megamenuPosition = Math.round(Math.min(itemPosition, containerWidth - megamenuWidth));

            megamenu.style.right = megamenuPosition + 'px';
        } else {
            const itemPosition = element.offsetLeft;
            const megamenuPosition = Math.round(Math.min(itemPosition, containerWidth - megamenuWidth));

            megamenu.style.left = megamenuPosition + 'px';
        }
    }

    onItemMouseLeave(item: NavigationLink): void {
        if (this.hoveredItem === item) {
            this.hoveredItem = null;
        }
    }

    onSubItemClick(): void {
        this.hoveredItem = null;
    }

    validarToken(){
        let token = localStorage.getItem('token')
        
        if(token == null && this.items.length > 5){
            this.items.splice(5,2)
            return
        }

        if(token != null){
            this.semilla.ValidarObjeto(token,5).subscribe(res => {
                if(res.split('|')[0] == "0"){
                    this.items = navigationComplete
                }else{
                    if(this.items.length > 5)
                        this.items.splice(5,2)
                }
            },err =>{
                if(this.items.length > 5)
                    this.items.splice(5,2)
            });
        }
    }
}
