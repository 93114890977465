import { Component, OnInit } from '@angular/core';
import { Archivo } from '../../interfaces/semilla/archivo';
import { MatDialogRef } from '@angular/material/dialog';

export interface Section {
  name: string;
  url: string;
}

@Component({
  selector: 'app-list-videos',
  templateUrl: './list-videos.component.html',
  styleUrls: ['./list-videos.component.scss']
})
export class ListVideosComponent implements OnInit {
  
  folders: Archivo[]

  constructor(private dialogRef:MatDialogRef<ListVideosComponent>) {
    
  }

  ngOnInit(): void {
  }

  seleccionar(video: Archivo){
    this.dialogRef.close(video);
  }


}
