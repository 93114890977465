import { Component, OnInit } from '@angular/core';
import { TipoObjeto } from 'src/app/shared/interfaces/semilla/tipo-objeto';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SemillaService } from 'src/app/shared/api/semilla.service';

@Component({
  selector: 'app-tipos-modificar',
  templateUrl: './tipos-modificar.component.html',
  styleUrls: ['./tipos-modificar.component.scss']
})
export class TiposModificarComponent implements OnInit {
  tipo: TipoObjeto

  constructor(public ngxSmartModalService: NgxSmartModalService, private semilla: SemillaService) {
    this.tipo = ngxSmartModalService.getModalData('mod-tipo').data
  }

  ngOnInit(): void {
  }

  modificar(){
    this.semilla.ModificarTipoObjeto(localStorage.getItem('token'),this.tipo)
    .subscribe(res => {
      if(res.split("|")[0] == "0"){
        this.cancelar()
      }else{
        
      }   
    }, err => {

    })
  }

  cancelar(){
    this.ngxSmartModalService.getModal('mod-tipo').close();
  }

}
