import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SemillaService } from 'src/app/shared/api/semilla.service';
import { AsociaPerfil } from 'src/app/shared/interfaces/semilla/asocia-perfil';
import { PerfilMantenimiento } from 'src/app/shared/interfaces/semilla/perfil';
import { Cliente } from 'src/app/shared/interfaces/semilla/cliente';
import { ClienteMantenimiento } from 'src/app/shared/interfaces/semilla/cliente-man';

@Component({
  selector: 'app-asoc-perf-modificar',
  templateUrl: './asoc-perf-modificar.component.html',
  styleUrls: ['./asoc-perf-modificar.component.scss']
})
export class AsocPerfModificarComponent implements OnInit {
  cliente: ClienteMantenimiento
  perfil: PerfilMantenimiento
  asociaciones: AsociaPerfil[]
  perfilUsuario: AsociaPerfil

  constructor(public ngxSmartModalService: NgxSmartModalService, private semilla: SemillaService) {
    this.perfil = ngxSmartModalService.getModalData('mod-perfusr').data
    this.cliente = ngxSmartModalService.getModalData('mod-perfusr').cliente
    this.asociaciones = ngxSmartModalService.getModalData('mod-perfusr').asoc
    this.perfilUsuario = {
      ACTIVO: false,
      FECHA_INGRESO: "",
      FECHA_MODIFICACION: "",
      ID_PERFIL: this.perfil.ID_PERFIL,
      ID_PERFIL_USUARIO: 0,
      ID_USUARIO: this.cliente.ID_USUARIO,
      USUARIO_INGRESO: "",
      USUARIO_MODIFICACION: ""
    }
  
    this.asociaciones.forEach(element => {
      if(element.ID_PERFIL == this.perfil.ID_PERFIL && element.ID_USUARIO == this.cliente.ID_USUARIO){
        this.perfilUsuario = element
      }
    });
  }

  ngOnInit(): void {
  }

  modificar(){
    if(this.perfilUsuario.ID_PERFIL_USUARIO == 0){
      this.semilla.CrearPerfilesUsuario(localStorage.getItem('token'), this.perfilUsuario).subscribe(res => {
        if(res.split("|")[0] == "0"){
          this.cancelar()
        }else{
          
        }  
      }, err => {
          
      })
    }else{
      this.semilla.ModificarPerfilesUsuario(localStorage.getItem('token'), this.perfilUsuario).subscribe(res => {
        if(res.split("|")[0] == "0"){
          this.cancelar()
        }else{
          
        }  
      }, err => {
          
      })
    }
  }

  cancelar(){
    this.ngxSmartModalService.getModal('mod-perfusr').close();
  }
}
