<div class="card">
    <div class="card-header">
        <h5>Editar Perfil</h5>
    </div>
    <div class="card-divider"></div>
    <div class="card-body">
        <div class="row no-gutters">
            <div class="col-12 col-lg-7 col-xl-6 fix-modal">
                <div class="form-group">
                    <label>Nombres</label>
                    <input type="text" class="form-control" placeholder="Ingresa nombres">
                </div>
                <div class="form-group">
                    <label>Apellidos</label>
                    <input type="email" class="form-control" placeholder="Ingresa apellidos">
                </div>
                <div class="form-group" >
                    <label>Apellidos</label>
                    <select id="checkout-country" class="form-control form-control-select2 select" >
                        <option>Elije un usuario...</option>
                        <option>Efectivo</option>
                        <option>Tarjeta de crédito</option>
                        <option>Tarjeta de débito</option>
                    </select>
                </div>
                <div class="form-group" >
                    <label>Apellidos</label>
                    <select id="checkout-country" class="form-control form-control-select2 select" >
                        <option>Elije un usuario...</option>
                        <option>Efectivo</option>
                        <option>Tarjeta de crédito</option>
                        <option>Tarjeta de débito</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>DPI</label>
                    <input type="number" class="form-control" placeholder="Ingresa DPI">
                </div>

                <div class="fix-buttons">
                    <div class="form-group mt-5 mb-0">
                        <button (click)="cancelar()" class="btn btn-primary">Cancelar</button>
                    </div>
                    <div class="form-group mt-5 mb-0">
                        <button class="btn btn-primary">Agregar</button>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>