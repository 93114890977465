import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SemillaService } from 'src/app/shared/api/semilla.service';
import { MatDialog } from '@angular/material/dialog';
import { PerfilMantenimiento } from 'src/app/shared/interfaces/semilla/perfil';

@Component({
  selector: 'app-perfiles-crear',
  templateUrl: './perfiles-crear.component.html',
  styleUrls: ['./perfiles-crear.component.scss']
})
export class PerfilesCrearComponent implements OnInit {

  
  perfil: PerfilMantenimiento

  constructor(public ngxSmartModalService: NgxSmartModalService, private semilla:SemillaService, public dialog: MatDialog) {
    this.perfil = {
      DESCRIPCION: "",
      ESTATUS: true,
      FECHA_INGRESO: "",
      FECHA_MODIFICACION: "",
      ID_PERFIL : 0,
      NOMBRE: "",
      USUARIO_INGRESO: "",
      USUARIO_MODIFICACION: ""
    }
  }

  ngOnInit(): void {

  }

  cancelar(){
    this.ngxSmartModalService.getModal('add-perfil').close();
  }

  agregar(){
    this.semilla.CrearPerfil(localStorage.getItem('token'),this.perfil)
    .subscribe(res => {
      if(res.split("|")[0] == "0"){
        this.cancelar()
      }else{
        
      }   
    }, err => {

    })
  }

}
