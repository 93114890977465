import { Component, OnInit } from '@angular/core';
import { ClienteMantenimiento } from 'src/app/shared/interfaces/semilla/cliente-man';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SemillaService } from 'src/app/shared/api/semilla.service';

@Component({
  selector: 'app-usuarios-modificar',
  templateUrl: './usuarios-modificar.component.html',
  styleUrls: ['./usuarios-modificar.component.scss']
})
export class UsuariosModificarComponent implements OnInit {
  usuario: ClienteMantenimiento


  constructor(public ngxSmartModalService: NgxSmartModalService, private semilla: SemillaService) { 
    this.usuario = ngxSmartModalService.getModalData('mod-usr').data
    
  }

  ngOnInit(): void {
  }

  modificar(){
    this.semilla.ModificarUsuario(localStorage.getItem('token'),this.usuario)
    .subscribe(res => {
      if(res.split("|")[0] == "0"){
        this.cancelar()
      }else{
        
      }   
    }, err => {

    })
  }

  cancelar(){
    this.ngxSmartModalService.getModal('mod-usr').close();
  }
}
