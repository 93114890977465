import { Component, OnInit } from '@angular/core';
import { SemillaDireccion } from 'src/app/shared/interfaces/semilla/perfil/semilla-direccion';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SemillaService } from 'src/app/shared/api/semilla.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CircleComponent } from '../../progress/circle/circle.component';
import { identifierModuleUrl } from '@angular/compiler';
import { ManTiposObjetosComponent } from 'src/app/modules/account/pages/mantipos-obj/mantiposobj.component';

@Component({
  selector: 'app-direcciones-crear',
  templateUrl: './direcciones-crear.component.html',
  styleUrls: ['./direcciones-crear.component.scss']
})
export class DireccionesCrearComponent implements OnInit {

  direccion: SemillaDireccion
  errorRegistrar: boolean
  messageRegistrar: string
  interior: boolean
  interiorMensaje: string

  constructor(public ngxSmartModalService: NgxSmartModalService, private semilla:SemillaService, public dialog: MatDialog) {
    this.direccion = {
      ALIAS: "",
      DIRECCION: "",
      ID: 0,
      REGION: "SERVICIO"
    }
    this.interior = false
    this.interiorMensaje = ""

    this.errorRegistrar = false
    this.messageRegistrar = ''
  }

  ngOnInit(): void {

  }

  cambioDir(event: any){
    if(this.direccion.REGION == "SERVICIO 0"){
      this.interior = true
      this.interiorMensaje = "Para el interior del país, el envío se realiza por medio Guatex. Se utilizará la modalidad por pagar para el mismo, por lo que, serán ellos quienes cobren el envío."
    }else{
      this.interior = false
    }
  }

  cancelar(){
    this.ngxSmartModalService.getModal('add-dir').close();
  }

  agregar(){
    this.errorRegistrar = false
    this.messageRegistrar = ''

    if(this.direccion.ALIAS.trim().length == 0){
      this.errorRegistrar = true
      this.messageRegistrar = 'El campo de alias no debe estar vacio'
      return
    }

    if(this.direccion.DIRECCION.trim().length == 0){
      this.errorRegistrar = true
      this.messageRegistrar = 'El campo de dirección no debe estar vacio'
      return
    }

    this.semilla.CrearDireccion(localStorage.getItem('token'),this.direccion)
    .subscribe( res => {
      if(res.split("|")[0] == "0"){
        
        this.direccion = {
          ALIAS: "",
          DIRECCION: "",
          ID: 0,
          REGION: "SERVICIO"
        }
        this.cancelar()
      }else{
        
      }      
    }, err => {
      
    });
  }

}
