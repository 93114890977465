import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MobileMenuService } from '../../../../shared/services/mobile-menu.service';
import { mobileMenu } from '../../../../../data/mobile-menu';
import { MobileMenuItem } from '../../../../shared/interfaces/mobile-menu-item';
import { SemillaService } from 'src/app/shared/api/semilla.service';

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnDestroy, OnInit {
    private destroy$: Subject<any> = new Subject();

    isOpen = false;
    links: MobileMenuItem[] = mobileMenu;
    linksComplete: MobileMenuItem[] = mobileMenu;

    constructor(public mobilemenu: MobileMenuService,private semilla: SemillaService) {
        this.validarToken()
    }

    ngOnInit(): void {
        this.mobilemenu.isOpen$.pipe(takeUntil(this.destroy$)).subscribe(isOpen => this.isOpen = isOpen);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onItemClick(event: MobileMenuItem): void {
        if (event.type === 'link') {
            this.mobilemenu.close();
        }
    }

    validarToken(){
        let token = localStorage.getItem('token')
        
        if(token == null && this.links.length > 5){
            this.links.splice(5,2)
            return
        }

        if(token != null){
            this.semilla.ValidarObjeto(token,5).subscribe(res => {
                if(res.split('|')[0] == "0"){
                    this.links = this.linksComplete
                }else{
                    if(this.links.length > 5)
                        this.links.splice(5,2)
                }
            },err =>{
                if(this.links.length > 5)
                    this.links.splice(5,2)
            });
        }
    }
}
