<div class="card">
    <div class="card-header">
        <h5>Crear Archivo</h5>
    </div>
    <div class="card-divider"></div>
    <div class="card-body">
        <div class="row no-gutters">
            <div class="col-12 col-lg-7 col-xl-6 fix-modal">
                <div class="form-group">
                    <label>Nombre</label>
                    <input [(ngModel)]="multimedia.NOMBRE"  type="text" class="form-control" placeholder="Ingresa nombre">
                </div>
                <div class="form-group">
                    <label>Informacion</label>
                    <input [(ngModel)]="multimedia.INFORMACION" type="text" class="form-control" placeholder="Ingresa información">
                </div>
                <div class="form-group">
                    <input type="file" (change)="handleFileInput($event.target.files)">
                </div>
                <div class="fix-buttons">
                    <div class="form-group mt-5 mb-0">
                        <button (click)="cancelar()" class="btn btn-primary">Cancelar</button>
                    </div>
                    <div class="form-group mt-5 mb-0">
                        <button (click)="agregar()" class="btn btn-primary">Agregar</button>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>