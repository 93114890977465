<div class="card">
    <div class="card-header">
        <h5>Modificar Dirección</h5>
    </div>
    <div class="card-divider"></div>
    <div class="card-body">
        <div class="row no-gutters">
            <div class="col-12 col-lg-7 col-xl-6 fix-modal">
                <div class="form-group">
                    <label>Alias</label>
                    <input [(ngModel)]="direccion.ALIAS" type="text" class="form-control" placeholder="Ingresa alias">
                </div>
                <div class="form-group">
                    <label>Dirección</label>
                    <input [(ngModel)]="direccion.DIRECCION" type="text" class="form-control" placeholder="Ingresa dirección">
                </div>
                <div class="form-group" >
                    <label>Región</label>
                    <select [(ngModel)]="direccion.REGION" (change)="cambioDir($event)" id="checkout-country" class="form-control form-control-select2 select" >
                        <option value="SERVICIO">Dentro del Perimetro de la Capital</option>
                        <option value="SERVICIO 30">Zona 18</option>
                        <option value="SERVICIO 30">Todo Mixco</option>
                        <option value="SERVICIO 30">San Cristobal</option>
                        <option value="SERVICIO 35">Fraijanes</option>
                        <option value="SERVICIO 35">Santa Catarina Pinula</option>
                        <option value="SERVICIO 35">San Jose Pinula</option>
                        <option value="SERVICIO 40">Carretera a lo de Dieguez</option>
                        <option value="SERVICIO">Carretera al Salvador hasta Km. 10</option>
                        <option value="SERVICIO 30">Carretera al Salvador hasta Km. 20</option>
                        <option value="SERVICIO 35">Carretera al Salvador hasta Km. 30</option>
                        <option value="SERVICIO 35">Villa Canales</option>
                        <option value="SERVICIO 35">Boca del Monte</option>
                        <option value="SERVICIO 45">Colmenas</option>
                        <option value="SERVICIO 35">Villa Nueva</option>
                        <option value="SERVICIO 35">San Miguel Petapa</option>
                        <option value="SERVICIO 45">Amatitlan</option>
                        <option value="SERVICIO 45">San Lucas Sacatepequez</option>
                        <option value="SERVICIO 45">Ciudad Quetzal</option>
                        <option value="SERVICIO 50">San Raymundo</option>
                        <option value="SERVICIO 0">Interior de la República</option>
                    </select>
                </div>
                <span *ngIf="interior" style="color: red;">{{interiorMensaje}}</span>
                <div class="fix-buttons">
                    <div class="form-group mt-5 mb-0">
                        <button (click)="cancelar()" class="btn btn-primary">Cancelar</button>
                    </div>
                    <div class="form-group mt-5 mb-0">
                        <button (click)="modificar()" class="btn btn-primary">Modificar</button>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>