<div class="account-menu">

    <div *ngIf="!validarToken">
        <form class="account-menu__form">
            <div class="account-menu__form-title">Entrar a tu cuenta</div>
            <div class="form-group">
                <label for="header-signin-email" class="sr-only">Usuario</label>
                <input [(ngModel)]="user" name="user" id="header-signin-email" type="email" class="form-control form-control-sm" placeholder="Usuario">
            </div>
            <div class="form-group">
                <label for="header-signin-password" class="sr-only">Contraseña</label>
                <div class="account-menu__form-forgot">
                    <input [(ngModel)]="pass" name="pass" id="header-signin-password" type="password" class="form-control form-control-sm" placeholder="Contraseña">
                    <!--<a href="" class="account-menu__form-forgot-link">Forgot?</a>-->
                </div>
            </div>
            <div style="text-align: center;">
                <a (click)="olvidaste()" class="olvidaste">¿Olvidaste tu contraseña?</a>  <br>
            </div>
            
            <div class="form-group account-menu__form-button">
                <button type="button" (click)="ingresar()" class="btn btn-primary btn-sm">Ingresar</button>
            </div>
            <div style="font-size: smaller;text-align: center;">
                <span *ngIf="error" style="color: red;">Datos Erroneos</span>
            </div>
            
            <div class="account-menu__form-link"><a routerLink="/home/login" (click)="closeMenu.emit()">Crear una cuenta</a></div>

            <div *ngIf="olvidastePass" style="margin-top: 15px;">
                <h4 class="account-menu__form-title" style="font-size: 18px;">Ingresa tu correo</h4>
                <div class="form-group" style="font-size: 12px;">
                    <input [(ngModel)]="correoEnviar" name="user" type="text" class="form-control" placeholder="Ingrese Correo">
                </div>
                <span *ngIf="errorEnviar" style="color: red; font-size: 11px;" >{{errorEnviarMensaje}}</span> 
                <button (click)="cancelarOlvidaste()" style="margin-right: 10px;" type="submit" class="btn btn-primary mt-2 mt-md-3 mt-lg-4">Cancelar</button>
                <button (click)="enviarPassword()" type="submit" class="btn btn-primary mt-2 mt-md-3 mt-lg-4">Enviar</button>
            </div>
        </form>
    </div>

    <div *ngIf="validarToken">
        <div class="account-menu__divider"></div>
        <a routerLink="/account/dashboard" class="account-menu__user" (click)="closeMenu.emit()">
            <!--
            <div class="account-menu__user-avatar">
                <img src="assets/images/avatars/avatar-3.jpg" alt="">
            </div> -->
            <div class="account-menu__user-info">
                <div class="account-menu__user-name">{{nombre}}</div>
                <div class="account-menu__user-email">{{correo}}</div>
            </div>
           
        </a>
        <div class="account-menu__divider"></div>
        <ul class="account-menu__links">
            <li><a routerLink="/account/profile" (click)="closeMenu.emit()">Editar Perfil</a></li>
            <li><a routerLink="/account/orders" (click)="closeMenu.emit()">Historial de Ordenes</a></li>
            <li><a routerLink="/account/addresses" (click)="closeMenu.emit()">Direcciones</a></li>
            <li><a routerLink="/account/password" (click)="closeMenu.emit()">Contraseña</a></li>
        </ul>
        <div class="account-menu__divider"></div>
        <ul class="account-menu__links">
            <li><a  (click)="salir()">Salir</a></li>
        </ul>
    </div>
</div>
