<div class="site-header">
    <div *ngIf="layout === 'classic'" class="site-header__middle container">
        <div class="site-header__logo">
            <a routerLink="/">
                <img height="100px" width="200px" src="/assets/images/semilla/semilla_logo.png" alt="">
            </a>
        </div>
        <div class="site-header__search">
            <app-header-search></app-header-search>
        </div>
        <div class="site-header__phone">
            <div class="site-header__phone-title">Llámanos</div>
            <div class="site-header__phone-number">{{ store.primaryPhone }}</div>
        </div>
    </div>

    <div class="site-header__nav-panel">
        <app-header-nav [departments]="layout === 'classic'" [logo]="layout === 'compact'" [search]="layout === 'compact'"></app-header-nav>
    </div>
</div>
