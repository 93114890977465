import { Component, OnInit } from '@angular/core';
import { PerfilMantenimiento } from 'src/app/shared/interfaces/semilla/perfil';
import { Objeto } from 'src/app/shared/interfaces/semilla/objeto';
import { AsociaObjeto } from 'src/app/shared/interfaces/semilla/asocia-objeto';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SemillaService } from 'src/app/shared/api/semilla.service';

@Component({
  selector: 'app-asoc-obj-modificar',
  templateUrl: './asoc-obj-modificar.component.html',
  styleUrls: ['./asoc-obj-modificar.component.scss']
})
export class AsocObjModificarComponent implements OnInit {
  perfil: PerfilMantenimiento
  objeto: Objeto
  asociaciones: AsociaObjeto[]
  objetoPerfil: AsociaObjeto

  constructor(public ngxSmartModalService: NgxSmartModalService, private semilla: SemillaService) { 
    this.objeto = ngxSmartModalService.getModalData('mod-objperf').data
    this.perfil = ngxSmartModalService.getModalData('mod-objperf').perfi
    this.asociaciones = ngxSmartModalService.getModalData('mod-objperf').asoc
    this.objetoPerfil = {
      ACTIVO: false,
      CONSULTAR: false,
      CREAR: false,
      ELIMINAR: false,
      ID_OBJETO: this.objeto.ID_OBJETO,
      ID_PERFIL: this.perfil.ID_PERFIL,
      ID_PERFIL_OBJETO: 0,
      MODIFICAR: false,
      NOMBRE: ""
    }
  
    this.asociaciones.forEach(element => {
      if(element.ID_PERFIL == this.perfil.ID_PERFIL && element.ID_OBJETO == this.objeto.ID_OBJETO){
        this.objetoPerfil = element
      }
    });
  }

  ngOnInit(): void {
  }

  modificar(){
    if(this.objetoPerfil.ID_PERFIL_OBJETO == 0){
      this.semilla.CrearObjetosPerfiles(localStorage.getItem('token'), this.objetoPerfil).subscribe(res => {
        if(res.split("|")[0] == "0"){
          this.cancelar()
        }else{
          
        }  
      }, err => {
          
      })
    }else{
      this.semilla.ModificarObjetosPerfiles(localStorage.getItem('token'), this.objetoPerfil).subscribe(res => {
        if(res.split("|")[0] == "0"){
          this.cancelar()
        }else{
          
        }  
      }, err => {
          
      })
    }
  }

  cancelar(){
    this.ngxSmartModalService.getModal('mod-objperf').close();
  }

}
