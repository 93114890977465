import { Injectable } from '@angular/core';
import { Observable, from, of} from 'rxjs';
import { Category } from '../interfaces/category';
import { HttpClient } from '@angular/common/http';
import { Brand } from '../interfaces/brand';
import { Product } from '../interfaces/product';
import { ProductsList } from '../interfaces/list';
import { SerializedFilterValues } from '../interfaces/filter';
import { map} from 'rxjs/operators';
import {
    getBestsellers,
    getFeatured,
    getLatestProducts,
    getProduct,
    getRelatedProducts,
    getSpecialOffers,
    getTopRated,
    getShopCategoriesBySlugs,
    getShopCategoriesTree,
    getShopCategory,
    getBrands,
    getProductsList,
} from '../../../fake-server';

import { NgxSoapService, Client, ISoapMethodResponse } from 'ngx-soap';
import { stringify } from 'querystring';
import { switchMap } from 'rxjs/operators';
import { SemillaService } from './semilla.service';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';


const WSDL_ENVIROMENT: string = "hola";

export interface ListOptions {
    page?: number;
    limit?: number;
    sort?: string;
    filterValues?: SerializedFilterValues;
}

@Injectable({
    providedIn: 'root'
})
export class ShopService {
    client: Client;

    constructor(
        private http: HttpClient,private soap: NgxSoapService, private  semilla: SemillaService, private router: Router
    ) { 
       
    }

    /**
     * Returns category object by slug.
     *
     * @param slug - Unique human-readable category identifier.
     */
    getCategory(slug: string): Observable<Category> {
        this.tokenValidator()

        return this.getBackendResponse('DevuelveCategoriaNuevo', 
            { 
                slugCategoria: slug,
            }).pipe(map((res) =>{
                let dinamico = JSON.parse(res.result.DevuelveCategoriaNuevoResult)
                let objectP:Category = dinamico
                objectP.parents = []
                return objectP;
        }))
    }

    /**
     * Returns a category tree.
     *
     * @param parent - If a parent is specified then its descendants will be returned.
     * @param depth  - Maximum depth of category tree.
     */
    getCategories(parent: Partial<Category> = null, depth: number = 0): Observable<Category[]> {
        this.tokenValidator()

        return getShopCategoriesTree(parent ? parent.slug : null, depth);
    }

    /**
     * Returns an array of the specified categories.
     *
     * @param slugs - Array of slugs.
     * @param depth - Maximum depth of category tree.
     */
    getCategoriesBySlug(slugs: string[], depth: number = 0): Observable<Category[]> {
        this.tokenValidator()

        return getShopCategoriesBySlugs(slugs, depth);
    }

    /**
     * Returns paginated products list.
     * If categorySlug is null then a list of all products should be returned.
     *
     * @param categorySlug    - Unique human-readable category identifier.
     * @param options         - Options.
     * @param options.page    - Page number (optional).
     * @param options.limit   - Maximum number of items returned at one time (optional).
     * @param options.sort    - The algorithm by which the list should be sorted (optional).
     * @param options.filters - An object whose keys are filter slugs and values ​​are filter values (optional).
     */
    getProductsList(categorySlug: string|null, options: ListOptions): Observable<ProductsList> {
        this.tokenValidator()
        var price = false

        if(options.filterValues != undefined)
            if(options.filterValues['price'] != null)
                price = true

        if(localStorage.getItem('busqueda') == null){
            return this.getBackendResponse('DevuelveProductosNuevo', 
            { 
                Limite: ('limit' in options) ? options.limit : 12, 
                Pagina: ('page' in options) ? options.page : 1, 
                sort:('sort' in options) ? options.sort : 'default',
                slugCategoria: (categorySlug != null) ? categorySlug: null, 
                precio: (price) ? options.filterValues['price'] : null 
            }).pipe(map((res) =>{
                
                let dinamico = JSON.parse(res.result.DevuelveProductosNuevoResult)
                let objectP:ProductsList = dinamico

                if(objectP.filterValues['price'] == null)
                    delete objectP.filterValues['price']

                if(objectP.pages == 0)
                    objectP.pages = 1
                
                return objectP;
            }))
        }else{
            return this.getBackendResponse('DevuelveProductosFiltrado', 
            { 
                Limite: ('limit' in options) ? options.limit : 12, 
                Pagina: ('page' in options) ? options.page : 1, 
                sort:('sort' in options) ? options.sort : 'default',
                slugCategoria: (categorySlug != null) ? categorySlug: null, 
                precio: (price) ? options.filterValues['price'] : null,
                palabra: localStorage.getItem('busqueda')
            }).pipe(map((res) =>{
                let dinamico = JSON.parse(res.result.DevuelveProductosFiltradoResult)
                let objectP:ProductsList = dinamico

                dinamico.filters = [dinamico.filters[1]]

                if(objectP.filterValues['price'] == null)
                    delete objectP.filterValues['price']

                if(objectP.pages == 0)
                    objectP.pages = 1
                
                return objectP;
        }))
        }
        
        //return getProductsList(categorySlug, options, "");
    }

    getProduct(productSlug: string): Observable<Product> {
        this.tokenValidator()

        return this.getBackendResponse('DevuelveProductoNuevo', 
            { 
                slugProducto: productSlug,
            }).pipe(map((res) =>{
                let dinamico = JSON.parse(res.result.DevuelveProductoNuevoResult)
                let objectP:Product = dinamico
                return objectP;
        }))
    }

    /**
     * Returns popular brands.
     */
    getPopularBrands(): Observable<Brand[]> {
        this.tokenValidator()
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/brands/popular.json
         */
        // return this.http.get<Brand[]>('https://example.com/api/shop/brands/popular.json');

        // This is for demonstration purposes only. Remove it and use the code above.
        return getBrands();
    }

    getBestsellers(limit: number = null): Observable<Product[]> {
        this.tokenValidator()

        return this.getBackendResponse('DevuelveBestSeller', 
        { 
            limit: limit,
        }).pipe(map((res) =>{
            let dinamico = JSON.parse(res.result.DevuelveBestSellerResult)
            let objectP:Product[] = dinamico
            return objectP;
        }))
        
        //return getBestsellers(limit);
    }

    getTopRated(limit: number = null): Observable<Product[]> {
        this.tokenValidator()
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/top-rated.json?limit=3
         *
         * where:
         * - limit = limit
         */
        // const params: {[param: string]: string} = {};
        //
        // if (limit) {
        //     params.limit = limit.toString();
        // }
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/top-rated.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getTopRated(limit);
    }

    getSpecialOffers(limit: number = null): Observable<Product[]> {
        this.tokenValidator()
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/special-offers.json?limit=3
         *
         * where:
         * - limit = limit
         */
        // const params: {[param: string]: string} = {};
        //
        // if (limit) {
        //     params.limit = limit.toString();
        // }
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/special-offers.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return this.getBackendResponse('DevuelveFeatureProduct', 
        { 
            limit: limit,
        }).pipe(map((res) =>{
            let dinamico = JSON.parse(res.result.DevuelveFeatureProductResult)
            let objectP:Product[] = dinamico
            return objectP;
        }))
        return getSpecialOffers(limit);
    }

    getFeaturedProducts(categorySlug: string = null, limit: number = null): Observable<Product[]> {
        this.tokenValidator()

        return this.getBackendResponse('DevuelveFeatureProduct', 
        { 
            limit: limit,
        }).pipe(map((res) =>{
            let dinamico = JSON.parse(res.result.DevuelveFeatureProductResult)
            let objectP:Product[] = dinamico
            return objectP;
        }))

        return getFeatured(categorySlug, limit);
    }

    getLatestProducts(categorySlug: string = null, limit: number = null): Observable<Product[]> {
        this.tokenValidator()

        return this.getBackendResponse('DevuelveLatestProduct', 
        { 
            limit: limit,
        }).pipe(map((res) =>{
            let dinamico = JSON.parse(res.result.DevuelveLatestProductResult)
            let objectP:Product[] = dinamico
            return objectP;
        }))

        return getLatestProducts(categorySlug, limit);
    }

    getRelatedProducts(product: Partial<Product>): Observable<Product[]> {
        this.tokenValidator()
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/related.json?for=water-tap
         *
         * where:
         * - for = product.slug
         */
        // const params: {[param: string]: string} = {
        //     for: product.slug,
        // };
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/related.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getRelatedProducts(product);
    }

    getBackendResponse(method: string, params: any): Observable<ISoapMethodResponse>{
        return this.getClient().pipe(switchMap((res) => {
            return res.call(method, params)
        }));
    }

    getClient(): Observable<Client>{
        return from(this.soap.createClient('assets/Semilla.wsdl').then((res) => {return res;}));
    }

    tokenValidator(){
        if(localStorage.getItem('token') == null)
          return
    
        const decoded = jwt_decode(localStorage.getItem('token'));
    
        if (decoded.exp === undefined) return null;
      
        const date = new Date(0); 
        date.setUTCSeconds(decoded.exp);
    
        if(new Date() > date){
          localStorage.setItem('session', 't')
          this.router.navigate(['/home/login'])
        }
      }
}
