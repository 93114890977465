import { Component, OnInit } from '@angular/core';
import { PerfilMantenimiento } from 'src/app/shared/interfaces/semilla/perfil';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SemillaService } from 'src/app/shared/api/semilla.service';

@Component({
  selector: 'app-perfiles-modificar',
  templateUrl: './perfiles-modificar.component.html',
  styleUrls: ['./perfiles-modificar.component.scss']
})
export class PerfilesModificarComponent implements OnInit {
  perfil: PerfilMantenimiento

  constructor(public ngxSmartModalService: NgxSmartModalService, private semilla: SemillaService) {
    this.perfil = ngxSmartModalService.getModalData('mod-perfil').data
  }

  ngOnInit(): void {
  }

  modificar(){
    this.semilla.ModificarPerfilMan(localStorage.getItem('token'),this.perfil)
    .subscribe(res => {
      if(res.split("|")[0] == "0"){
        this.cancelar()
      }else{
        
      }   
    }, err => {

    })
  }

  cancelar(){
    this.ngxSmartModalService.getModal('mod-perfil').close();
  }

}
