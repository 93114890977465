<div class="card">
    <div class="card-header">
        <h5>Modificar {{nombre}}</h5>
    </div>
    <div class="card-divider"></div>
    <div class="card-body">
        <div class="row no-gutters">
            <div class="col-12 col-lg-7 col-xl-6 fix-modal">
                <div class="form-group">
                    <label>Nombre</label>
                    <input [(ngModel)]="multimedia.NOMBRE" type="text" class="form-control" placeholder="Ingresa Nombre">
                </div>
                <div class="form-group tx-area">
                    <label>Información</label>
                    <textarea [(ngModel)]="multimedia.INFORMACION" name="" id="" cols="30" rows="10"></textarea>
                </div>
                
                <div class="fix-buttons">
                    <div class="form-group mt-5 mb-0">
                        <button (click)="cancelar()" class="btn btn-primary">Cancelar</button>
                    </div>
                    <div class="form-group mt-5 mb-0">
                        <button (click)="modificar()" class="btn btn-primary">Modificar</button>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>