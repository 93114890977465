import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-usuarios-crear',
  templateUrl: './usuarios-crear.component.html',
  styleUrls: ['./usuarios-crear.component.scss']
})
export class UsuariosCrearComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  cancelar(){

  }

  agregar(){
    
  }

}
