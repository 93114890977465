import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SemillaService } from '../../api/semilla.service';

@Component({
  selector: 'app-eliminar',
  templateUrl: './eliminar.component.html',
  styleUrls: ['./eliminar.component.scss']
})
export class EliminarComponent implements OnInit {
  nombre:string
  data: any
  idElement:number
  object: string

  constructor(public ngxSmartModalService: NgxSmartModalService, private semilla: SemillaService) { 
    this.data =  ngxSmartModalService.getModalData('eliminar')
    this.nombre = this.data.nombre
    this.idElement = this.data.idElement
    this.object = this.data.object
  }

  ngOnInit(): void {
  }

  cancelar(){
    this.ngxSmartModalService.getModal('eliminar').close();
  }

  eliminar(){
    switch(this.object){
      case "direccion":
        this.semilla.EliminarDireccion(localStorage.getItem('token'),this.idElement).subscribe(res => {
          if(res.split("|")[0] == "0"){
            this.cancelar()
          }else{
            
          }
        }, err => {

        })
      break

      case "tipos":
        this.semilla.EliminarTipoObjeto(localStorage.getItem('token'),this.idElement).subscribe(res => {
          if(res.split("|")[0] == "0"){
            this.cancelar()
          }else{
            
          }
        }, err => {

        })

      break

      case "perfiles":
        this.semilla.EliminarPerfil(localStorage.getItem('token'),this.idElement).subscribe(res => {
          if(res.split("|")[0] == "0"){
            this.cancelar()
          }else{
            
          }
        }, err => {

        })
      break

      case "objeto":
        this.semilla.EliminarObjeto(localStorage.getItem('token'),this.idElement).subscribe(res => {
          if(res.split("|")[0] == "0"){
            this.cancelar()
          }else{
            
          }
        }, err => {

        })
      break;

      case "cliente":
        this.semilla.EliminarUsuario(localStorage.getItem('token'),this.idElement).subscribe(res => {
          if(res.split("|")[0] == "0"){
            this.cancelar()
          }else{
            
          }
        }, err => {

        })

      break

      case "multimedia":
        this.semilla.EliminarArchivo(localStorage.getItem('token'),this.idElement).subscribe(res => {
          if(res.split("|")[0] == "0"){
            this.cancelar()
          }else{
            
          }
        }, err => {

        })

      break
    }
  }
}
