<div class="block">
    <div class="container">
        <div class="not-found">
            <div class="not-found__404">
                Oops! Error 404
            </div>

            <div class="not-found__content">
                <h1 class="not-found__title">Página no enconotrada</h1>

                <p class="not-found__text">
                    No pudimos encontrar lo que estas buscando.
                </p>

                <a class="btn btn-secondary btn-sm" href="index.html">Ir a página de inicio</a>
            </div>
        </div>
    </div>
</div>
