<div class="card">
    <div class="card-header">
        <h5>Crear Usuario</h5>
    </div>
    <div class="card-divider"></div>
    <div class="card-body">
        <div class="row no-gutters">
            <div class="col-12 col-lg-7 col-xl-6 fix-modal">
                <div class="form-group">
                    <label>Nombres</label>
                    <input type="text" class="form-control" placeholder="Ingresa nombres">
                </div>
                <div class="form-group">
                    <label>Apellidos</label>
                    <input type="text" class="form-control" placeholder="Ingresa apellidos">
                </div>

                <div class="form-group">
                    <label>Contraseña</label>
                    <input type="password" class="form-control" placeholder="Ingresa contraseña">
                </div>
                <div class="form-group">
                    <label>Usuario</label>
                    <input type="text" class="form-control" placeholder="Ingresa usuario">
                </div>
                <div class="form-group" >
                    <label>Estatus</label>
                    <input type="checkbox" name="" id="">
                </div>
                <div class="fix-buttons">
                    <div class="form-group mt-5 mb-0">
                        <button (click)="cancelar()" class="btn btn-primary">Cancelar</button>
                    </div>
                    <div class="form-group mt-5 mb-0">
                        <button (click)="agregar()" class="btn btn-primary">Agregar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>