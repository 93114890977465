<app-page-header [header]="'Mi Cuenta'" [breadcrumbs]="[
    {label: 'Home',       url: '/'},
    {label: 'Mi Cuenta', url: ''}
]"></app-page-header>

<div class="block">
    <div class="container">
        <div class="row">
            <div class="col-md-6 d-flex">
                <div class="card flex-grow-1 mb-md-0">
                    <div class="card-body">
                        <h3 class="card-title">Login</h3>
                        <form>
                            <div class="form-group">
                                <label>Usuario</label>
                                <input [(ngModel)]="user" name="user" type="text" class="form-control" placeholder="Ingrese Usuario">
                            </div>
                            <div class="form-group">
                                <label>Contraseña</label>
                                <input [(ngModel)]="pass" name="pass" type="password" class="form-control" placeholder="Ingrese Contraseña">
                                <!--
                                <small class="form-text text-muted">
                                    <a href="">Forgotten Password</a>
                                </small>
                                -->
                            </div>
                            <a (click)="olvidaste()" class="olvidaste">¿Olvidaste tu contraseña?</a>  <br>
                            <button (click)="loguear()" type="submit" class="btn btn-primary mt-2 mt-md-3 mt-lg-4">Ingresar</button>
                            <span style="color: red;" *ngIf="errorLogin">{{messageLogin}}</span> 
                        </form>
                        <div *ngIf="olvidastePass" style="margin-top: 50px;">
                            <h4 class="card-title">Ingresa tu correo</h4>
                            <div class="form-group">
                                <input [(ngModel)]="correo" name="user" type="text" class="form-control" placeholder="Ingrese Correo">
                            </div>
                            <span *ngIf="errorEnviar" style="color: red;" >{{errorEnviarMensaje}}</span> 
                            <button (click)="cancelarOlvidaste()" style="margin-right: 10px;" type="submit" class="btn btn-primary mt-2 mt-md-3 mt-lg-4">Cancelar</button>
                            <button (click)="enviarPassword()" type="submit" class="btn btn-primary mt-2 mt-md-3 mt-lg-4">Enviar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 d-flex mt-4 mt-md-0">
                <div class="card flex-grow-1 mb-0">
                    <div class="card-body">
                        <h3 class="card-title">Registrarse</h3>
                        <div class="form-group">
                            <label>Nombres</label>
                            <input [(ngModel)]="info.NOMBRES" type="text" class="form-control" placeholder="Ingresa nombres">
                        </div>
                        <div class="form-group">
                            <label>Apellidos</label>
                            <input [(ngModel)]="info.APELLIDOS" type="text" class="form-control" placeholder="Ingresa apellidos">
                        </div>
                        <div class="form-group">
                            <label>NIT</label>
                            <input [(ngModel)]="info.NIT" type="text" class="form-control" placeholder="Ingresa Nit o CF">
                        </div>
                        <div class="form-group">
                            <label>Teléfono</label>
                            <input [(ngModel)]="info.CELULAR" type="number" class="form-control" placeholder="Ingresa Teléfono">
                        </div>
                        <div class="form-group">
                            <label>Correo</label>
                            <input [(ngModel)]="info.EMAIL" type="email" class="form-control" placeholder="Ingresa correo">
                        </div>
                        <div class="form-group">
                            <label>Usuario</label>
                            <input [(ngModel)]="info.NOMBRES_OTRO" type="text" class="form-control" autocomplete="new-password" placeholder="Ingresa usuario">
                        </div>
                        <div class="form-group">
                            <label>Contraseña</label>
                            <input [(ngModel)]="info.APELLIDOS_OTRO" type="password" class="form-control" autocomplete="new-password" placeholder="Ingresa una contraseña">
                        </div>
                        <div class="form-group">
                            <label>Repetir contraseña</label>
                            <input [(ngModel)]="repeatPass" type="password" class="form-control" placeholder="Repite la contraseña">
                        </div>
                        <button (click)="registrar()" type="submit" class="btn btn-primary mt-2 mt-md-3 mt-lg-4">Registrar</button><br>
                        <span  style="color: red;" *ngIf="errorRegistrar">{{messageRegistrar}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
