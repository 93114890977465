import { Component } from '@angular/core';
import { Cliente } from 'src/app/shared/interfaces/semilla/cliente';
import { SemillaService } from 'src/app/shared/api/semilla.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CircleComponent } from 'src/app/shared/modals/progress/circle/circle.component';
import {Router} from "@angular/router"

@Component({
    selector: 'app-login',
    templateUrl: './page-login.component.html',
    styleUrls: ['./page-login.component.scss']
})
export class PageLoginComponent {
    user: string
    pass: string
    repeatPass: string
    info: Cliente
    errorLogin: boolean
    messageLogin: string
    errorRegistrar: boolean
    messageRegistrar: string
    redirect: boolean
    olvidastePass: boolean
    correo: string
    errorEnviar: boolean
    errorEnviarMensaje: string

    constructor(private semilla: SemillaService, public dialog: MatDialog, private router: Router) { 
        var ua = navigator.userAgent;
        this.correo = ""
        this.olvidastePass = false
        this.errorEnviar = false 
        this.errorEnviarMensaje = ""

        if(localStorage.getItem('session') != null){
            let dialogRef:MatDialogRef<CircleComponent> = this.dialog.open(CircleComponent)
            dialogRef.componentInstance.titulo = "Cargando"
            dialogRef.componentInstance.session("Tu sesión ha vencido")
            localStorage.removeItem('session')    
        }

        
        this.clear()

        if(this.redirect){
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)){
                window.location.reload()
            }
        }

        this.user = ''
        this.pass = ''
        this.errorLogin = false
        this.messageLogin = ''
        this.errorRegistrar = false
        this.messageRegistrar = ''
        
        this.limpiarRegistro()      
    }

    clear(){
        localStorage.removeItem('exp')
        localStorage.removeItem('nombre')
        localStorage.removeItem('correo')
        if(localStorage.getItem('token')!= null){
            this.redirect = true
            localStorage.removeItem('token')
        }
        localStorage.removeItem('busqueda')
    }

    cancelarOlvidaste(){
        this.olvidastePass = false
        this.correo = ""
        this.errorEnviar = false
    }

    enviarPassword(){
        let regCorreo: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if(!regCorreo.test(this.correo)){
            this.errorEnviar = true
            this.errorEnviarMensaje= 'El correo no tiene un formato valido'
            return
        }

        let dialogRef:MatDialogRef<CircleComponent> = this.dialog.open(CircleComponent)
        dialogRef.componentInstance.titulo = "Enviando"

        this.semilla.EnviarPassword(this.correo).subscribe(res => {
            if(res.split("|")[0] == "0"){
                dialogRef.componentInstance.ok("Se ha enviado la contraseña a tu correo")
                this.correo = ""
                this.errorEnviar = false
                this.olvidastePass = false
            }else{
                dialogRef.componentInstance.error("El correo ingresado no pertenece a ningun usuario")
            }
        }, err => {
            dialogRef.componentInstance.error("Error en el servidor")
        })
    }

    olvidaste(){
        this.olvidastePass = true
    }

    limpiarRegistro(){
        this.info = {
            ACTIVO: true,
            APELLIDOS: "",
            APELLIDOS_OTRO: "",
            CELULAR: "",
            CELULAR_OTRO: " ",
            CODIGO_SAP: " ",
            DPI: "",
            DPI_OTRO: " ",
            EMAIL: "",
            EMAIL_OTRO: " ",
            ID_CLIENTE: 0,
            ID_TIPO_CLIENTE: 0,
            NIT: "",
            NOMBRES: "",
            NOMBRES_OTRO: "",
            OBSERVACIONES: " ",
            USUARIO: "",
            USUARIO_INGRESO: " ",
            USUARIO_MODIFICACION: " ",
            ID_USUARIO: 0
        }
        this.repeatPass = ''
        this.correo = ""
    }
    
    loguear(){
        this.errorLogin = false
        this.messageLogin = ''

        if(this.user.trim() == ""){
            this.messageLogin = "Debes llenar el campo Usuario"
            this.errorLogin = true
            return
        }

        if(this.pass.trim() == ""){
            this.messageLogin = "Debes llenar el campo Contraseña"
            this.errorLogin = true
            return
        }

        //Registrando cliente
        let dialogRef:MatDialogRef<CircleComponent> = this.dialog.open(CircleComponent)
        dialogRef.componentInstance.titulo = "Ingresando"

        this.semilla.Login(this.user, this.pass).subscribe((res) => {
            if(res.status == "0"){
                dialogRef.close()

                var ua = navigator.userAgent;

                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)){
                    localStorage.setItem('movil','true');
                }

                localStorage.setItem('token',res.token)
                localStorage.setItem('correo', res.correo)
                localStorage.setItem('nombre', res.nombre)
                localStorage.setItem('exp', res.expiracion)
                localStorage.setItem('id', res.id)
                this.router.navigate(['/'])
                return;
            }else{
                dialogRef.componentInstance.error("Error en tus credenciales")
            }
        }, (error) =>{
            dialogRef.componentInstance.error("Error en el servidor")
        })
        
    }

    ingresar(){
        
    }

    registrar(){
        this.errorRegistrar = false
        this.messageRegistrar = ''

        if(!this.validador(this.info.NOMBRES, "Nombres"))
            return

        if(!this.validador(this.info.APELLIDOS, "Apellidos"))
            return

        if(!this.validador(this.info.NIT, "NIT"))
            return

        if(!this.validador(this.info.CELULAR.toString(), "Teléfono"))
            return

        if(this.info.CELULAR.toString().includes(' ')){
            this.errorRegistrar = true
            this.messageRegistrar = 'El teléfono no debe contener espacios'
            return
        }

        if(this.info.CELULAR.toString().length != 8){
            this.errorRegistrar = true
            this.messageRegistrar = 'El teléfono debe tener una longitud de 8 digitos'
            return
        }
        
        if(!this.validador(this.info.EMAIL, "Correo"))
            return

        let regCorreo: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if(!regCorreo.test(this.info.EMAIL)){
            this.errorRegistrar = true
            this.messageRegistrar = 'El correo no tiene un formato valido'
            return
        }

        if(!this.validador(this.info.NOMBRES_OTRO, "Usuario"))
            return

        if(!this.validador(this.info.APELLIDOS_OTRO, "Contraseña"))
            return

        if(this.info.APELLIDOS_OTRO.length < 5){
            this.errorRegistrar = true
            this.messageRegistrar = 'La contraseña debe tener un largo minimo de 5 carácteres'
            return
        }

        if(this.info.APELLIDOS_OTRO != this.repeatPass){
            this.errorRegistrar = true
            this.messageRegistrar = 'Las contraseñas no coinciden'
            return
        }

        //Registrando cliente
        let dialogRef:MatDialogRef<CircleComponent> = this.dialog.open(CircleComponent)
        dialogRef.componentInstance.titulo = "Registrando Cliente"
        
        this.semilla.RegistroCliente(this.info).subscribe((res) =>{
            if(res.split("|")[0] == "0"){
                dialogRef.componentInstance.ok("Registrado Correctamente")
                this.limpiarRegistro()
            }else{
                dialogRef.componentInstance.error("Error al registrar", res.split("|")[1])
            }
        }, err => {
            dialogRef.componentInstance.error("Error en el servidor")
        })
    }

    validador(object: string, mensaje: string): boolean{
        if(object.trim() == ""){
            this.errorRegistrar = true
            this.messageRegistrar = "Debes llenar el campo " + mensaje
            return false
        }

        return true
    }
}
