import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SemillaService } from 'src/app/shared/api/semilla.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CircleComponent } from '../../progress/circle/circle.component';
import { TipoObjeto } from 'src/app/shared/interfaces/semilla/tipo-objeto';
import { Objeto } from 'src/app/shared/interfaces/semilla/objeto';

@Component({
  selector: 'app-objetos-crear',
  templateUrl: './objetos-crear.component.html',
  styleUrls: ['./objetos-crear.component.scss']
})
export class ObjetosCrearComponent implements OnInit {
  tipos: TipoObjeto[]
  objeto: Objeto

  constructor(public ngxSmartModalService: NgxSmartModalService, private semilla:SemillaService, public dialog: MatDialog) { 
    this.objeto = {
      ACTIVO: true,
      DESCRIPCION: "",
      FECHA_INGRESO: "",
      FECHA_MODIFICACION: "",
      ID_OBJETO: 0,
      ID_PADRE: 0,
      ID_TIPO_OBJETO: 0,
      NOMBRE: "",
      URL: "",
      USUARIO_INGRESO: "",
      USUARIO_MODIFICACION: ""
    }
    this.tipos = []
    this.ObtenerData()
  }

  ObtenerData(){
    let dialogRef:MatDialogRef<CircleComponent> = this.dialog.open(CircleComponent)
    dialogRef.componentInstance.titulo = "Cargando"

    this.semilla.ObtenerTiposObjetos(localStorage.getItem('token')).subscribe(res => {

        if(res.split("|").length > 1){
          
        }else{
          this.tipos = JSON.parse(res)
        }

        dialogRef.close()
    }, err => {
        dialogRef.componentInstance.error("Error en el servidor")
    })
  }

  ngOnInit(): void {
  }

  cancelar(){
    this.ngxSmartModalService.getModal('add-obj').close();
  }

  agregar(){
    
    this.semilla.CrearObjeto(localStorage.getItem('token'),this.objeto)
      .subscribe(res => {
        if(res.split("|")[0] == "0"){
          this.cancelar()
        }else{
          
        }   
      }, err => {

    })
    
  }
  
}

