import { NgModule } from '@angular/core';

// modules (angular)
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

// modules (third-party)
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ModalModule } from 'ngx-bootstrap/modal';

// directives
import { ClickDirective } from './directives/click.directive';
import { CollapseContentDirective, CollapseDirective, CollapseItemDirective } from './directives/collapse.directive';
import { DepartmentsAreaDirective } from './directives/departments-area.directive';
import { DropdownDirective } from './directives/dropdown.directive';
import { FakeSlidesDirective } from './directives/fake-slides.directive';
import { OwlPreventClickDirective } from './directives/owl-prevent-click.directive';

// components
import { AlertComponent } from './components/alert/alert.component';
import { IconComponent } from './components/icon/icon.component';
import { InputNumberComponent } from './components/input-number/input-number.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PostCardComponent } from './components/post-card/post-card.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ProductComponent } from './components/product/product.component';
import { QuickviewComponent } from './components/quickview/quickview.component';
import { RatingComponent } from './components/rating/rating.component';

// pipes
import { ColorTypePipe } from './pipes/color-type.pipe';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { ModalPruebaComponent } from './modals/modal-prueba/modal-prueba.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { AgregarComponent } from './modals/objetos/agregar/agregar.component';
import { EliminarComponent } from './modals/eliminar/eliminar.component';
import { CircleComponent } from './modals/progress/circle/circle.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ErrorComponent } from './modals/server/error/error.component';
import { UsuariosCrearComponent } from './modals/mantenimientos/usuarios-crear/usuarios-crear.component';
import { UsuariosModificarComponent } from './modals/mantenimientos/usuarios-modificar/usuarios-modificar.component';
import { DireccionesCrearComponent } from './modals/mantenimientos/direcciones-crear/direcciones-crear.component';
import { DireccionesModificarComponent } from './modals/mantenimientos/direcciones-modificar/direcciones-modificar.component';
import { ObjetosCrearComponent } from './modals/mantenimientos/objetos-crear/objetos-crear.component';
import { ObjetosModificarComponent } from './modals/mantenimientos/objetos-modificar/objetos-modificar.component';
import { PerfilesCrearComponent } from './modals/mantenimientos/perfiles-crear/perfiles-crear.component';
import { PerfilesModificarComponent } from './modals/mantenimientos/perfiles-modificar/perfiles-modificar.component';
import { TiposCrearComponent } from './modals/mantenimientos/tipos-crear/tipos-crear.component';
import { TiposModificarComponent } from './modals/mantenimientos/tipos-modificar/tipos-modificar.component';
import { AsocPerfModificarComponent } from './modals/mantenimientos/asoc-perf-modificar/asoc-perf-modificar.component';
import { AsocObjModificarComponent } from './modals/mantenimientos/asoc-obj-modificar/asoc-obj-modificar.component';
import { TextosCrearComponent } from './modals/mantenimientos/textos-crear/textos-crear.component';
import { TextosModificarComponent } from './modals/mantenimientos/textos-modificar/textos-modificar.component';
import { ArchivosCrearComponent } from './modals/mantenimientos/archivos-crear/archivos-crear.component';
import { ImagenesCrearComponent } from './modals/mantenimientos/imagenes-crear/imagenes-crear.component';
import { VideosComponent } from './modals/videos/videos.component';
import { ListVideosComponent } from './modals/list-videos/list-videos.component';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
    declarations: [
        // directives
        ClickDirective,
        CollapseContentDirective,
        CollapseDirective,
        CollapseItemDirective,
        DepartmentsAreaDirective,
        DropdownDirective,
        FakeSlidesDirective,
        OwlPreventClickDirective,
        // components
        AlertComponent,
        IconComponent,
        InputNumberComponent,
        LoadingBarComponent,
        PageHeaderComponent,
        PaginationComponent,
        PostCardComponent,
        ProductCardComponent,
        ProductComponent,
        QuickviewComponent,
        RatingComponent,
        // pipes
        CurrencyFormatPipe,
        ColorTypePipe,
        ModalPruebaComponent,
        AgregarComponent,
        EliminarComponent,
        CircleComponent,
        ErrorComponent,
        UsuariosCrearComponent,
        UsuariosModificarComponent,
        DireccionesCrearComponent,
        DireccionesModificarComponent,
        ObjetosCrearComponent,
        ObjetosModificarComponent,
        PerfilesCrearComponent,
        PerfilesModificarComponent,
        TiposCrearComponent,
        TiposModificarComponent,
        AsocPerfModificarComponent,
        AsocObjModificarComponent,
        TextosCrearComponent,
        TextosModificarComponent,
        ArchivosCrearComponent,
        ImagenesCrearComponent,
        VideosComponent,
        ListVideosComponent
    ],
    imports: [
        // modules (angular)
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule,
        // modules (third-party)
        CarouselModule,
        ModalModule.forRoot(),
        NgxSmartModalModule.forChild(),
        MatProgressSpinnerModule,
        MatListModule,
        MatIconModule,
        MatDialogModule
    ],
    exports: [
        // directives
        ClickDirective,
        CollapseContentDirective,
        CollapseDirective,
        CollapseItemDirective,
        DepartmentsAreaDirective,
        DropdownDirective,
        FakeSlidesDirective,
        OwlPreventClickDirective,
        // components
        AlertComponent,
        IconComponent,
        InputNumberComponent,
        LoadingBarComponent,
        PageHeaderComponent,
        PaginationComponent,
        PostCardComponent,
        ProductCardComponent,
        ProductComponent,
        QuickviewComponent,
        RatingComponent,
        // pipes
        ColorTypePipe,
        CurrencyFormatPipe
    ]
})
export class SharedModule { }
