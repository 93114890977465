import { Component, OnInit } from '@angular/core';
import { Archivo } from 'src/app/shared/interfaces/semilla/archivo';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SemillaService } from 'src/app/shared/api/semilla.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-archivos-crear',
  templateUrl: './imagenes-crear.component.html',
  styleUrls: ['./imagenes-crear.component.scss']
})
export class ImagenesCrearComponent implements OnInit {
  ImageBaseData:string | ArrayBuffer=null;
  multimedia: Archivo
  constructor(public ngxSmartModalService: NgxSmartModalService, private semilla:SemillaService, public dialog: MatDialog) { 
    this.multimedia = {
      DATA: "",
      FECHA_CREACION: "",
      FECHA_MODIFICACION: "",
      ID_MULTIMEDIA: 0,
      INFORMACION: "",
      NOMBRE: "",
      TIPO_MULTIMEDIA: 4,
      USUARIO_CREACION: "",
      USUARIO_MODIFICACION: ""
    }
  }

  ngOnInit(): void {
  }

  cancelar(){
    this.ngxSmartModalService.getModal('add-dir').close();
  }

  handleFileInput(files: FileList) {
    let me = this;
    let file = files[0];
    this.multimedia.NOMBRE = file.name
    this.multimedia.INFORMACION = file.type
    let reader = new FileReader();
    let that = this
    reader.readAsDataURL(file);
    reader.onload = function () {
      me.ImageBaseData=reader.result;
      that.multimedia.DATA = me.ImageBaseData.toString().substr(13 + that.multimedia.INFORMACION.length)
    };
    reader.onerror = function (error) {

    };
 }

  agregar(){
    this.semilla.CrearArchivo(localStorage.getItem('token'),this.multimedia)
    .subscribe( res => {
      if(res.split("|")[0] == "0"){
        
        
      }else{
        
      }      
    }, err => {
      
    });
  }

}
