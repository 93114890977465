import { Component, OnInit } from '@angular/core';
import { Archivo } from 'src/app/shared/interfaces/semilla/archivo';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SemillaService } from 'src/app/shared/api/semilla.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-textos-crear',
  templateUrl: './textos-crear.component.html',
  styleUrls: ['./textos-crear.component.scss']
})
export class TextosCrearComponent implements OnInit {
  nombre: string
  multimedia: Archivo

  constructor(public ngxSmartModalService: NgxSmartModalService, private semilla:SemillaService, public dialog: MatDialog) { 
    this.nombre = ngxSmartModalService.getModalData('add-tex').nombre
    this.multimedia = {
      DATA: "",
      FECHA_CREACION: "",
      FECHA_MODIFICACION: "",
      ID_MULTIMEDIA: 0,
      INFORMACION: "",
      NOMBRE: "",
      TIPO_MULTIMEDIA: ngxSmartModalService.getModalData('add-tex').tipo,
      USUARIO_CREACION: "",
      USUARIO_MODIFICACION: ""
    }
  }

  cancelar(){
    this.ngxSmartModalService.getModal('add-tex').close();
  }

  ngOnInit(): void {
  }

  b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode(parseInt(p1, 16))
    }))
  }

  agregar(){
    switch(this.nombre){
      case 'Texto':
        if(this.multimedia.NOMBRE == "CAT1"){
          this.multimedia.DATA = this.b64EncodeUnicode(this.multimedia.INFORMACION)
          this.multimedia.INFORMACION = "data"
        }

        this.semilla.CrearTextos(localStorage.getItem('token'),this.multimedia).subscribe( res => {
          if(res.split("|")[0] == "0"){
            this.cancelar()
            
          }else{
            
          }      
        }, err => {
          
        });
        break;

      case 'Video':
        this.semilla.CrearVideo(localStorage.getItem('token'),this.multimedia).subscribe( res => {
          if(res.split("|")[0] == "0"){
            this.cancelar()
            
          }else{
            
          }      
        }, err => {
          
        });
        break;

      case 'Imagen':
        this.semilla.CrearImagen(localStorage.getItem('token'),this.multimedia).subscribe( res => {
          if(res.split("|")[0] == "0"){
            this.cancelar()
            
          }else{
            
          }      
        }, err => {
          
        });
        break;
    }
  }
}
