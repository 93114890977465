<div class="card">
    <div class="card-header">
        <h5>Crear Objeto</h5>
    </div>
    <div class="card-divider"></div>
    <div class="card-body">
        <div class="row no-gutters">
            <div class="col-12 col-lg-7 col-xl-6 fix-modal">
                <div class="form-group">
                    <label>Nombre</label>
                    <input type="text" [(ngModel)]="objeto.NOMBRE" class="form-control" placeholder="Ingresa nombre">
                </div>
                <div class="form-group">
                    <label>Descripción</label>
                    <input type="text" [(ngModel)]="objeto.DESCRIPCION" class="form-control" placeholder="Ingresa Descripción">
                </div>
                <div class="form-group" >
                    <label>Tipo Objeto</label>
                    <select [(ngModel)]="objeto.ID_TIPO_OBJETO" id="checkout-country" class="form-control form-control-select2 select" >
                        <option *ngFor="let item of tipos" [ngValue]="item.ID_TIPO_OBJETO" >{{item.NOMBRE}}</option>
                    </select>
                </div>
                <div class="form-group" >
                    <label>Estatus</label>
                    <input [(ngModel)]="objeto.ACTIVO" type="checkbox" name="" id="">
                </div>
                <div class="fix-buttons">
                    <div class="form-group mt-5 mb-0">
                        <button (click)="cancelar()" class="btn btn-primary">Cancelar</button>
                    </div>
                    <div class="form-group mt-5 mb-0">
                        <button (click)="agregar()" class="btn btn-primary">Agregar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>