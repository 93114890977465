<!-- .block-slideshow -->
<div class="block-slideshow block" [ngClass]="{'block-slideshow--layout--full': !withDepartments, 'block-slideshow--layout--with-departments': withDepartments}">
    <div class="container">
        <div class="row">
            <div *ngIf="withDepartments" class="col-3 d-lg-block d-none" appDepartmentsArea></div>
            <div class="col-12" [ngClass]="{'col-lg-12': !withDepartments, 'col-lg-9': withDepartments}">
                <div class="block-slideshow__body">
                    <owl-carousel-o *ngIf="slides?.length" [options]="options" appOwlPreventClick>
                        <ng-template *ngFor="let slide of slides" carouselSlide>
                            <a class="block-slideshow__slide" [href]="slide.title" appClick>
                                <div class="block-slideshow__slide-image block-slideshow__slide-image--desktop" 
                                [ngStyle]="{'background-image': 'url('+(withDepartments ? slide.image_classic : slide.image_full)+')','background-repeat':'no-repeat','background-size':'cover'}"></div>
                                <div class="block-slideshow__slide-image block-slideshow__slide-image--mobile" 
                                [ngStyle]="{'background-image': 'url('+slide.image_mobile+')','background-repeat':'round','background-size':'contain'}"></div>
                                <div class="block-slideshow__slide-content">
                                    <div class="block-slideshow__slide-text" [innerHTML]="sanitizer.bypassSecurityTrustHtml(slide.text)"></div>
                                    <div class="block-slideshow__slide-button">
                                        <span (click)="comprar()" class="btn btn-primary btn-lg">Compra Ahora</span>
                                    </div>
                                </div>
                            </a>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- .block-slideshow / end -->
