<app-block-slideshow></app-block-slideshow>

<app-block-features layout="boxed"></app-block-features>

<app-block-products-carousel
    header="Productos populares"
    layout="grid-5"
    [rows]="2"
    [loading]="featuredProducts.loading"
    [products]="featuredProducts.products"
    [groups]="featuredProducts.groups"
    (groupChange)="groupChange(featuredProducts, $event)"
></app-block-products-carousel>

<!--
<app-block-banner></app-block-banner>
-->
<app-block-products header="Más Vendidos" layout="large-last" [products]="bestsellers$|async"></app-block-products>

<!--
<app-block-categories header="Categorias Populares" layout="compact" [categories]="popularCategories$|async"></app-block-categories>
-->
<app-block-products-carousel
    header="Nuevos Productos"
    layout="grid-5"
    [rows]="1"
    [loading]="latestProducts.loading"
    [products]="latestProducts.products"
    [groups]="latestProducts.groups"
    (groupChange)="groupChange(latestProducts, $event)"
></app-block-products-carousel>
<!--
<app-block-posts header="Latest News" layout="grid-nl" [posts]="posts"></app-block-posts>
-->

<!--
<app-block-brands [brands]="brands$|async"></app-block-brands>
-->

<app-block-product-columns>
    <!--
    <app-block-product-columns-item header="Mejor Puntuación" [products]="columnTopRated$|async"></app-block-product-columns-item>
    -->
    <app-block-product-columns-item header="Ofertas Especiales" [products]="columnSpecialOffers$|async"></app-block-product-columns-item>
    <app-block-product-columns-item header="Más Vendidos" [products]="columnBestsellers$|async"></app-block-product-columns-item>
</app-block-product-columns>
