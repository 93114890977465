import { Component, EventEmitter, Output } from '@angular/core';
import { SemillaService } from 'src/app/shared/api/semilla.service';
import {Router} from "@angular/router"
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CircleComponent } from 'src/app/shared/modals/progress/circle/circle.component';

@Component({
    selector: 'app-account-menu',
    templateUrl: './account-menu.component.html',
    styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent {
    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();
    validarToken: boolean
    user: string
    pass: string
    error: boolean
    nombre: string
    correo: string
    olvidastePass: boolean
    correoEnviar: string
    errorEnviar: boolean
    errorEnviarMensaje: string

    constructor(private semillaService: SemillaService, private router: Router, public dialog: MatDialog) { 
        this.validarToken = false
        this.error = false
        this.user = ''
        this.pass = ''
        this.nombre = ''
        this.correo = ''
        this.correoEnviar = ""
        this.olvidastePass = false
        this.errorEnviar = false 
        this.errorEnviarMensaje = ""
        this.validarToken = this.validarTokenSession()

        if(this.validarToken){
            this.nombre = localStorage.getItem('nombre')
            this.correo = localStorage.getItem('correo')
        }
    }

    enviarPassword(){
        let regCorreo: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if(!regCorreo.test(this.correoEnviar)){
            this.errorEnviar = true
            this.errorEnviarMensaje= 'El correo no tiene un formato valido'
            return
        }

        let dialogRef:MatDialogRef<CircleComponent> = this.dialog.open(CircleComponent)
        dialogRef.componentInstance.titulo = "Enviando"

        this.semillaService.EnviarPassword(this.correoEnviar).subscribe(res => {
            if(res.split("|")[0] == "0"){
                dialogRef.componentInstance.ok("Se ha enviado la contraseña a tu correo")
                this.correoEnviar = ""
                this.errorEnviar = false
                this.olvidastePass = false
            }else{
                dialogRef.componentInstance.error("El correo ingresado no pertenece a ningun usuario")
            }
        }, err => {
            dialogRef.componentInstance.error("Error en el servidor")
        })
    }

    olvidaste(){
        this.olvidastePass = true
    }

    cancelarOlvidaste(){
        this.olvidastePass = false
        this.correoEnviar = ""
        this.errorEnviar = false
    }

    validarTokenSession(){
        let token = localStorage.getItem('token')

        return token != null
    }

    ingresar(){
        this.semillaService.Login(this.user, this.pass).subscribe((res) => {
            if(res.status == "0"){
                localStorage.setItem('token',res.token);
                localStorage.setItem('token',res.token)
                localStorage.setItem('correo', res.correo)
                localStorage.setItem('nombre', res.nombre)
                localStorage.setItem('exp', res.expiracion)
                localStorage.setItem('id', res.id)

                this.router.url != '/home/login' ?  window.location.reload() :  this.router.navigate(['/']) ;
               
                return;
            }

            this.error = true
        }, (error) =>{
            this.error = true
        })
    }

    salir(){
        localStorage.removeItem('token')
        this.closeMenu.emit()
        this.router.navigate(['/home/login'])
    }
}
