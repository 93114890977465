import { Component, OnInit } from '@angular/core';
import { Objeto } from 'src/app/shared/interfaces/semilla/objeto';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SemillaService } from 'src/app/shared/api/semilla.service';
import { TipoObjeto } from 'src/app/shared/interfaces/semilla/tipo-objeto';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CircleComponent } from '../../progress/circle/circle.component';

@Component({
  selector: 'app-objetos-modificar',
  templateUrl: './objetos-modificar.component.html',
  styleUrls: ['./objetos-modificar.component.scss']
})
export class ObjetosModificarComponent implements OnInit {
  objeto: Objeto
  tipos: TipoObjeto[]
  
  constructor(public ngxSmartModalService: NgxSmartModalService, private semilla: SemillaService, public dialog: MatDialog) { 
    this.objeto = ngxSmartModalService.getModalData('mod-obj').data
    this.tipos = []
    this.ObtenerData()
  }

  ngOnInit(): void {
    
  }

  ObtenerData(){
    let dialogRef:MatDialogRef<CircleComponent> = this.dialog.open(CircleComponent)
    dialogRef.componentInstance.titulo = "Cargando"

    this.semilla.ObtenerTiposObjetos(localStorage.getItem('token')).subscribe(res => {

        if(res.split("|").length > 1){

        }else{
          this.tipos = JSON.parse(res)
        }

        dialogRef.close()
    }, err => {
        dialogRef.componentInstance.error("Error en el servidor")
    })
  }

  modificar(){
    this.semilla.ModificarObjeto(localStorage.getItem('token'),this.objeto)
    .subscribe(res => {
      if(res.split("|")[0] == "0"){
        this.cancelar()
      }else{
        
      }   
    }, err => {

    })
  }

  cancelar(){
    this.ngxSmartModalService.getModal('mod-obj').close();
  }

}
