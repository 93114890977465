export const theme = {
    name: 'Stroyka',
    author: {
        name: 'Kos',
        profile_url: '',
        profile_fb: 'https://www.facebook.com/VeterinariaLaSemilla',
        profile_tw: 'https://twitter.com/lasemillasa?lang=es',
        profile_yt: 'https://www.youtube.com/user/veterinarialasemilla',
        profile_in: 'https://www.instagram.com/veterinarialasemilla/'
    }
};
