import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DirectionService } from '../../../shared/services/direction.service';
import {Router} from "@angular/router"
import { Archivo } from 'src/app/shared/interfaces/semilla/archivo';
import { SemillaService } from 'src/app/shared/api/semilla.service';
import { element } from 'protractor';

@Component({
    selector: 'app-block-slideshow',
    templateUrl: './block-slideshow.component.html',
    styleUrls: ['./block-slideshow.component.scss']
})
export class BlockSlideshowComponent {
    @Input() withDepartments = false;
    archivos: Archivo[]
    complete: Archivo[]

    options = {
        autoplay:true,
        autoplayTimeout: 4000,
        nav: false,
        dots: true,
        loop: true,
        responsive: {
            0: {items: 1}
        },
        rtl: this.direction.isRTL()
    };

    slides = [
        {
            title: '',
            text: '',
            image_classic: 'assets/images/semilla/Home/Imagen1.jpg',
            image_full: 'assets/images/semilla/Home/Imagen1.jpg',
            image_mobile: 'assets/images/semilla/Home/Imagen1.jpg'
        }
    ];

    constructor(
        public sanitizer: DomSanitizer,
        private direction: DirectionService,
        private router: Router,
        private semilla: SemillaService
    ) { 
        this.ObtenerData()
    }

    ObtenerData(){
        this.semilla.ObtenerImagen(localStorage.getItem('token')).subscribe(res => {
            this.slides = []
            this.complete = JSON.parse(res)
            this.complete.forEach(element => {
                this.slides.push({
                    title: element.INFORMACION.split(',')[3],
                    text: '',
                    image_classic: element.INFORMACION.split(',')[0],
                    image_full: element.INFORMACION.split(',')[1],
                    image_mobile: element.INFORMACION.split(',')[2]
                })
            });

        }, err => {
            this.slides = [
                {
                    title: '',
                    text: '',
                    image_classic: 'assets/images/semilla/Home/Imagen1.jpg',
                    image_full: 'assets/images/semilla/Home/Imagen1.jpg',
                    image_mobile: 'assets/images/semilla/Home/Imagen1.jpg'
                }
            ];
        })
    }

    comprar(){
        //this.router.navigate(['shop/catalog'])
    }
}
