<div class="card">
    <div class="card-header">
        <h5>Modificar Usuario</h5>
    </div>
    <div class="card-divider"></div>
    <div class="card-body">
        <div class="row no-gutters">
            <div class="col-12 col-lg-7 col-xl-6 fix-modal">
                <div class="form-group">
                    <label>Nombres</label>
                    <input type="text" [(ngModel)]="usuario.NOMBRES" class="form-control" placeholder="Ingresa nombres">
                </div>
                <div class="form-group">
                    <label>Apellidos</label>
                    <input type="text" [(ngModel)]="usuario.APELLIDOS" class="form-control" placeholder="Ingresa apellidos">
                </div>
                <div class="form-group">
                    <label>DPI</label>
                    <input type="text" [(ngModel)]="usuario.DPI" class="form-control" placeholder="Ingresa dpi">
                </div>
                <div class="form-group">
                    <label>NIT</label>
                    <input type="text" [(ngModel)]="usuario.NIT" class="form-control" placeholder="Ingresa nit">
                </div>
                <div class="form-group">
                    <label>Celular</label>
                    <input type="text" [(ngModel)]="usuario.CELULAR" class="form-control" placeholder="Ingresa celular">
                </div>
                <div class="form-group">
                    <label>Correo</label>
                    <input type="text" [(ngModel)]="usuario.EMAIL" class="form-control" placeholder="Ingresa correo">
                </div>

            
                <div class="fix-buttons">
                    <div class="form-group mt-5 mb-0">
                        <button (click)="cancelar()" class="btn btn-primary">Cancelar</button>
                    </div>
                    <div class="form-group mt-5 mb-0">
                        <button (click)="modificar()" class="btn btn-primary">Modificar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>