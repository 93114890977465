import { MobileMenuItem } from '../app/shared/interfaces/mobile-menu-item';

export const mobileMenu: MobileMenuItem[] = [
    {type: 'link', label: 'Inicio', url: '/'},
    {type: 'link', label: 'Cremascotas', url: '/site/cremascotas'},
    {type: 'link', label: 'Productos', url: '/shop/catalog'},
    {type: 'link', label: 'Videos y Manuales', url: '/shop/catalog', children: [
        {type: 'link', label: 'Videos',          url: '/site/videos'},
        {type: 'link', label: 'Manuales',          url: '/account/manuales'}
    ]},
    {type: 'link', label: 'Semilla', url: '/shop/catalog', children: [
        {type: 'link', label: 'Quienes Somos',          url: '/site/about-us'},
        {type: 'link', label: 'Membresia',          url: '/site/membresia'},
        {type: 'link', label: 'Politicas',          url: '/site/politicas'}
    ]},
    {type: 'link', label: 'Administrador', url: '/shop/catalog', children: [
        {type: 'link', label: 'Ordenes pendientes',          url: '/account/ordenespen'},
        {type: 'link', label: 'Mantenimiento de objetos',          url: '/account/manobjetos'},
        {type: 'link', label: 'Mantenimiento de perfiles',          url: '/account/manperfiles'},
        {type: 'link', label: 'Mantenimiento de tipo de objetos',          url: '/account/mantiposobj'},
        {type: 'link', label: 'Mantenimiento de usuarios',          url: '/account/manusuarios'},
        {type: 'link', label: 'Asociar perfiles a usuarios',          url: '/account/manperfusuarios'},
        {type: 'link', label: 'Asociar objetos a perfiles',          url: '/account/manobjperf'}
    ]},
    {type: 'link', label: 'CMS', url: '/shop/catalog', children: [
        {type: 'link', label: 'Textos',          url: '/account/textos'},
        {type: 'link', label: 'Archivos',          url: '/account/archivos'},
        {type: 'link', label: 'Videos',          url: '/account/videosman'},
        {type: 'link', label: 'Imagenes',          url: '/account/imagenes'},
        {type: 'link', label: 'Pedidos',          url: '/site/pedidos'}
    ]},
    {type: 'link', label: 'Cerrar Sesión', url: '/home/login'},
];

export const mobileMenuComplete: MobileMenuItem[] = [
    {type: 'link', label: 'Inicio', url: '/'},
    {type: 'link', label: 'Cremascotas', url: '/site/cremascotas'},
    {type: 'link', label: 'Productos', url: '/shop/catalog'},
    {type: 'link', label: 'Videos y Manuales', url: '/shop/catalog', children: [
        {type: 'link', label: 'Videos',          url: '/site/videos'},
        {type: 'link', label: 'Manuales',          url: '/account/manuales'}
    ]},
    {type: 'link', label: 'Semilla', url: '/shop/catalog', children: [
        {type: 'link', label: 'Quienes Somos',          url: '/site/about-us'},
        {type: 'link', label: 'Membresia',          url: '/site/membresia'},
        {type: 'link', label: 'Politicas',          url: '/site/politicas'}
    ]},
    {type: 'link', label: 'Administrador', url: '/shop/catalog', children: [
        {type: 'link', label: 'Ordenes pendientes',          url: '/account/ordenespen'},
        {type: 'link', label: 'Mantenimiento de objetos',          url: '/account/manobjetos'},
        {type: 'link', label: 'Mantenimiento de perfiles',          url: '/account/manperfiles'},
        {type: 'link', label: 'Mantenimiento de tipo de objetos',          url: '/account/mantiposobj'},
        {type: 'link', label: 'Mantenimiento de usuarios',          url: '/account/manusuarios'},
        {type: 'link', label: 'Asociar perfiles a usuarios',          url: '/account/manperfusuarios'},
        {type: 'link', label: 'Asociar objetos a perfiles',          url: '/account/manobjperf'}
    ]},
    {type: 'link', label: 'CMS', url: '/shop/catalog', children: [
        {type: 'link', label: 'Textos',          url: '/account/textos'},
        {type: 'link', label: 'Archivos',          url: '/account/archivos'},
        {type: 'link', label: 'Videos',          url: '/account/videosman'},
        {type: 'link', label: 'Imagenes',          url: '/account/imagenes'},
        {type: 'link', label: 'Pedidos',          url: '/site/pedidos'}
    ]}
];
