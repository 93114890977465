import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent {
    palabra: string
    constructor(private router: Router) { 
        this.palabra = ""
    }

    busqueda(){
        if(this.palabra.trim() == '')
            return

        localStorage.setItem('busqueda', this.palabra)

        if(this.router.url != '/shop/catalog'){
            this.router.navigate(['../shop/catalog'])
        }else{
            window.location.reload()
        }
        
    }
}
