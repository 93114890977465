import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-circle',
  templateUrl: './circle.component.html',
  styleUrls: ['./circle.component.scss']
})
export class CircleComponent implements OnInit {

  titulo: string
  path: string
  response: boolean
  boton: boolean
  message: string

  constructor(private dialogRef:MatDialogRef<CircleComponent>) { 
    this.response = false
    this.boton = false
    this.message = ""
  }

  ngOnInit(): void {


  }

  cerrar(){
    this.dialogRef.close()
  }

  session(mensaje: string){
    this.titulo = mensaje
    this.path = "assets/images/modals/sesion.png"
    this.response = true
    this.boton = true
  }

  error(mensaje: string, desc?: string){
    this.titulo = mensaje
    this.path = "assets/images/modals/error.png"
    this.response = true
    this.boton = true
    this.message = (desc != null) ? desc : ""
  }

  ok(mensaje: string){
    this.titulo = mensaje
    this.path = "assets/images/modals/ok.png"
    this.response = true
    this.boton = true
  }
}
